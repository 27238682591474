import React from "react";
import Slider from "react-slick";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Session from "../utils/session";
import { useSelector } from "react-redux";

function LeftPanel() {
  const { banners_list, banner_path } = useSelector(state => state.match)

  const panelleft_slider = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };


  const navigate = useNavigate()

  const handleHomePage = () => {
    Session.setSession('activeMenu', '/Fantasy')
    navigate('/')
  }
  return (
    <div className="left_panel d-flex flex-column justify-content-between">
      <div className="left_slider">

        <Slider {...panelleft_slider}>
          {banners_list?.map((item, index) => {
            //console.log('banner item', item, index)
            return (
              <div key={index} className="" >
                <img src={banner_path + item.image} alt="" className="mw-100" />
              </div>
            )
          })}
        </Slider>

        {/* <Slider {...panelleft_slider}>
              <div>  <img src="https://ultrafair-api-staging.octallabs.com/static/banners/027719c00d6a742331c11744c5ae692a1677834315833.png" alt="" className="mw-100" /></div>
              <div>  <img src="https://ultrafair-api-staging.octallabs.com/static/banners/027719c00d6a742331c11744c5ae692a1677834315833.png" alt="" className="mw-100" /></div>
              <div>  <img src="https://ultrafair-api-staging.octallabs.com/static/banners/027719c00d6a742331c11744c5ae692a1677834315833.png" alt="" className="mw-100" /></div> 
          </Slider>  */}
      </div>


    </div>
  );
}

export default LeftPanel;
