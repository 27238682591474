import { React, useState } from "react";
import { Tabs, Tab, Offcanvas, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";


import BoostersTab from "./BoostersTab";
import SubscriptionsTab from "./SubscriptionsTab";
import DonationsTab from "./DonationsTab";
import RewardsTab from "./RewardsTab";
import helper from "../../../utils/helper";
import { BOOSTER_SHOP_TABS } from "../../../redux/constants/Shop";
import StandardBoosters from "../standardBoosters/StandardBoosters";
import { getShopCategory } from "../../../redux/actions/ShopActions";

const ShopHome = () => {

    const dispatch = useDispatch()

    const { shopTabs } = useSelector(state => state.shop)


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const TabIconAndTitle = ({ title }) => {
        return (
            <div className="booster_tabs">
                <img src={`../images/${title}.svg`} alt="" />
                {helper.textCapitalize(title)}

            </div>
        )
    }


    const handleBoosterTab = async (eventKey) => {
        dispatch({ type: BOOSTER_SHOP_TABS, payload: eventKey })
    }



    return (
        <>
            <div className="page_wrap px-4">
                <div className="booster_page">
                    <div className="booster_tab">
                        <Tabs
                            defaultActiveKey="booster"
                            id="uncontrolled-tab-example"
                            className=""
                            activeKey={shopTabs}
                            onSelect={(eventKey) => handleBoosterTab(eventKey)}
                        >
                            <Tab eventKey="boosters" title={<TabIconAndTitle title={'boosters'} />}  >
                                {shopTabs === 'boosters' && <BoostersTab />}
                            </Tab>

                            <Tab eventKey="subscription" title={<TabIconAndTitle title={'subscription'} />} >
                                {shopTabs === 'subscription' && <SubscriptionsTab />}
                            </Tab>

                            <Tab eventKey="donations" title={<TabIconAndTitle title={'donations'} />} >
                                {shopTabs === 'donations' && <DonationsTab />}
                            </Tab>

                            <Tab eventKey="rewards" title={<TabIconAndTitle title={'rewards'} />} >
                                {shopTabs === 'rewards' && <RewardsTab />}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShopHome