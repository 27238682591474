import React from "react";
import {
  Link,
  useNavigate,
  Outlet,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import CommonHeader from "../../../layout/CommonHeader";
import LeagueNameHeader from "./LeagueNameHeader";
import { useDispatch, useSelector } from "react-redux";
import { checkUnJoinedTeams, create_team_data, get_all_player_list_league } from "../../../redux/actions/LeagueAction";
import Session from "../../../utils/session";
import { EXITS_CREATE_TEAM, LEAGUE_STATE_DATA, TEAM_EXITS } from "../../../redux/constants/league";
import { toast } from "react-toastify";
import helper from "../../../utils/helper";
import { isEmpty } from "lodash";

const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { myContestTab, match_details, leagueDetails } = useSelector(state => state?.league)



  const handelBack = () => {
    const pathname = location?.pathname;

    if (pathname === '/league-create-team') {
      dispatch({ type: EXITS_CREATE_TEAM, payload: true })
      return
    }


    if (pathname === '/league-home') {
      navigate('/league-fantasy', { state: '', replace: true })
      return
    }

    navigate(-1);
    window.history.pushState(null, '', '/');
  }

  const createTeam = async () => {
    const res = await dispatch(checkUnJoinedTeams())
    if (res?.success) {
      var data = { "series_id": match_details?.series_id }
      Session?.clearSession('reload')
      const { results } = await dispatch(get_all_player_list_league(data, 'Create Team'))
      if (!isEmpty(results)) {
        var leagueData = { isDirectJoin: false, type: 'create_team', team_id: '' }
        dispatch({ type: LEAGUE_STATE_DATA, payload: leagueData })
        navigate('/league-create-team')
      } else {
        // toast.warn('Record Not Found!')
        navigate('/league-create-team', { state: { isRecord: true } })
      }
    } else {
      // toast.warn(res?.msg)
      dispatch({ type: TEAM_EXITS, payload: res?.msg })
    }

  }

  const pathname = location?.pathname

  const createTeamHeader = myContestTab == 'myTeams' && location.pathname === '/league-contest'
  const hideHeader = ["/league-team-preview"]
  const isHideHeader = hideHeader.includes(pathname)
  const isSelectTeam = location?.pathname === '/league-select-team'


  const sethomePage = () => {
    navigate('/Fantasy')
  }


  return (
    <>

      {
        !isHideHeader &&
        <div className={`top_header px-4 ${createTeamHeader && 'add_create_team_logo'}`} >
          <div className="back_arrow cursor">
            <div className="d-flex align-items-center">
              <div>
                <img src="./images/top_arrow.png" className="me-3" alt="" onClick={handelBack} />
                {!isSelectTeam && helper?.textConverter(leagueDetails?.name, myContestTab === 'myTeams' ? 15 : 50)}
              </div>

              {
                isSelectTeam && <span className="" style={{ marginLeft: '25%' }}>Select Team</span>
              }


              <div>
                <img className='ms-4' src='./images/home_menu.png' alt='' height={'29px'} width={'28px'} onClick={sethomePage} />
              </div>


            </div>
            <LeagueNameHeader />
          </div>

          {
            createTeamHeader &&
            <div className="text-green cursor btn_create_team  " onClick={createTeam}>
              <img src="./images/plus-black.png" alt="" className="me-1" style={{ width: '16px' }} />
              CREATE TEAM
            </div>
          }

        </div>
      }
    </>
  );
};

export default Header;
