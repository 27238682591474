import React from "react";
import { Link } from "react-router-dom";

function RightPanel() {




  return (
    <div className="right_panel d-flex flex-column justify-content-between align-items-center">
 

      <div className="download_app_icon">
        <Link to="/home" className="login_logo d-block"><img src="../images/logo.svg" /></Link>
        <h6 className="text-white pb-3">Fantasy App Available On Mobile!</h6>
        <Link to="" className="me-2">
          <img src="./images/app_store.png" alt="" />
        </Link>
        <Link to="">
          <img src="./images/play_store.png" alt="" />
        </Link>
      </div>

     
    </div>
  );
}

export default RightPanel;
