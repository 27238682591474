import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import RugbyMatchTimer from '../RugbyMatchTimer';
import helper from '../../../../utils/helper';


const MatchHeader = () => {

    const location = useLocation()
    const startTime = helper.start_time;
    const { matchDetails, team_preview_soccer, teamScore } = useSelector(state => state?.soccer)
    const { playerList } = useSelector(state => state?.soccerTeam)

    const teamCount = ['/soccer-create-team']
    const pathname = location?.pathname

    const isDisplayTeamCount = teamCount.includes(pathname) && playerList?.length > 0


    return (
        <>
            <div className="middle_soccer_heade pt-4 px-0">
                <ul className="d-flex align-items-center justify-content-between list-unstyled mb-0 pb-0">
                    <li className="kxip d-flex align-items-center">
                        <figure className="mb-0">
                            <img src={matchDetails?.localteam_flag} className="logo_image_match" />
                        </figure>
                        {
                            isDisplayTeamCount ? (
                                <h5 className="text-white ps-2 mb-0">{matchDetails?.localteam_short_name}({playerList?.filter((item) => item.isSelected === true && matchDetails?.localteam_id === item?.team_id).length})</h5>
                            ) : (
                                <h5 className="text-white ps-2 mb-0">{matchDetails?.localteam_short_name}</h5>
                            )
                        }


                    </li>
                    <div>
                        {isDisplayTeamCount && <span style={{ color: '#F7B32B', fontSize: '12px' }}>Credit Limit 100</span>}
                        {matchDetails?.timestamp_start > startTime ?
                            <RugbyMatchTimer item={matchDetails} hour={true} class_name={false} hr={'left'} />
                            :
                            <div className="not-started">
                                {matchDetails?.match_status === 'In Progress' ? <strong className='live-status'>Live</strong> : matchDetails?.match_status}
                            </div>
                        }
                    </div>

                    <li className="rcbT d-flex align-items-center">
                        {
                            isDisplayTeamCount ? (
                                <h5 className="text-white ps-2 mb-0">{matchDetails?.visitorteam_short_name}({playerList?.filter((item) => item.isSelected === true && matchDetails?.visitorteam_id === item?.team_id).length})</h5>
                            ) : (
                                <h5 className="text-white ps-2 mb-0">{matchDetails?.visitorteam_short_name}</h5>
                            )
                        }
                        <figure className="mb-0">
                            <img src={matchDetails?.visitorteam_flag} className="logo_image_match" />
                        </figure>
                    </li>
                </ul>

            </div>


            {
                matchDetails?.match_status === "Completed" || matchDetails?.match_status === "In Progress" ?
                    <div className="" style={{ background: 'black', color: 'white', fontWeight: 600, padding: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                            <span>Score</span>
                            <span>{teamScore?.teamScores?.minutesPlayed}</span>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                            <span>{teamScore?.localteam}</span>
                            <span>{teamScore?.teamScores?.score?.home} - {teamScore?.teamScores?.score?.away}</span>
                            <span>{teamScore?.visitorteam}</span>

                        </div>

                    </div>
                    : ''
            }

        </>
    )
}

export default MatchHeader