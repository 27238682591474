import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const location = useLocation()
  const navigate = useNavigate()
  var noBottomMenu = ["/league-create-team", "/league-save-team", "/league-review-team", "/league-team-detail", "/league-select-team", "/league-booster-select", "/league-team-preview", "/league-booster", "/league-transfer-history",
    "/league-player-info"]

  var checkBottom = noBottomMenu.includes(location?.pathname);


  const handelHome = () => {
    navigate('/league-home')
  }
  const handelContest = () => {
    navigate('/league-contest')
  }
  const handelStats = () => {
    navigate('/league-details')
  }



  return (

    <>
      {
        !checkBottom &&
        <div>
          <div className="bottom_menu menu_tabs_fx league_footer">
            <ul>
              <li className="cursor text-yellow cursor" onClick={handelHome}>
                <a className="active">
                  <img className="mb-1 active" src="./images/home_active.png" alt="" /> Main
                </a>
              </li>
              <li className="cursor text-light-blue offer_center cursor" onClick={handelContest}>
                <a className="">
                  <img className="mb-1 active" src="./images/league/contest.svg" alt="" />
                  Contest
                </a>
              </li>

              <li className="cursor text-light-red" onClick={handelStats}>
                <a className="">
                  <img className="mb-1 active" src="./images/league/stats-chart.svg" alt="" />
                  Stats
                </a>
              </li>
            </ul>
          </div>
        </div>
      }


    </>
  );
};

export default Footer;
