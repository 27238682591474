import React, { lazy } from "react";

import Loadable from "../components/loader/Loadable";
import RugbyLayout from "../layout/RugbyLayout";


const AllContestRugby = Loadable(lazy(() => import("../pages/rugbyGame/allContests/AllContest")));



const RugbyRoutes = {
    path: "/",
    element: <RugbyLayout />,
    children: [
        {
            path: '/all-contest-rugby',
            element: <AllContestRugby />
        },
    ],
};

export default RugbyRoutes;
