import React from 'react'

const Claim = () => {
    return (
        <div>
            <div className="not_found">
                <div>
                    <h1 className="text_white">Coming Soon...</h1>
                    <h6 className="">Follow the news and be the first to know...</h6>
                </div>
            </div>
        </div>
    )
}

export default Claim