
import { toast } from 'react-toastify';
import apiPath from '../../utils/apiPath'
import helper from '../../utils/helper'
import { LOADER } from '../constants/Auth';
import { BOOSTERS_CATEGORY, BOOSTERS_CATEGORY_LIST, SHOP_BOOSTERS_LIST, SHOP_SUBSCRIPTION_LIST } from '../constants/Shop';
import { get_profile } from "../actions/AuthActions";




var qs = require('qs');

export const getShopCategory = () => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        const res = await helper.api(apiPath.get_shop_category, 'get', {})

        if (res?.success) {
            dispatch({ type: BOOSTERS_CATEGORY, payload: res?.results })
            dispatch({ type: LOADER, payload: false })
            return res
        } else {
            dispatch({ type: LOADER, payload: false })
            return false
        }

    } catch (error) {
        dispatch({ type: LOADER, payload: false })
        // console.log('error in create trans id api::', error)
    }

}

export const getBoosterListByCategory = (id) => async (dispatch) => {

    try {
        dispatch({ type: BOOSTERS_CATEGORY_LIST, payload: [] })
        dispatch({ type: LOADER, payload: true })

        const path = id ? apiPath.get_shop_category + `?parentId=${id}` : apiPath.get_shop_category

        const res = await helper.api(path, 'get', {})

        if (res?.success) {
            dispatch({ type: BOOSTERS_CATEGORY_LIST, payload: res?.results })
            dispatch({ type: LOADER, payload: false })
            return res
        } else {
            dispatch({ type: LOADER, payload: false })
            return false
        }

    } catch (error) {
        dispatch({ type: BOOSTERS_CATEGORY_LIST, payload: [] })
        dispatch({ type: LOADER, payload: false })
        // console.log('error in create trans id api::', error)
    }

}


export const getBoosterListByCategoryAndSubCategory = (item) => async (dispatch) => {

    try {
        dispatch({ type: SHOP_BOOSTERS_LIST, payload: [] })
        dispatch({ type: LOADER, payload: true })

        const path = item ? apiPath.get_shop_boosterListByCategory + `?categoryId=${item?.parentId}&subCategoryId=${item?.id}` : apiPath.get_shop_category

        const res = await helper.api(path, 'get', {})

        if (res?.success) {
            dispatch({ type: SHOP_BOOSTERS_LIST, payload: res?.results })
            dispatch({ type: LOADER, payload: false })
            return res
        } else {
            dispatch({ type: LOADER, payload: false })
            return false
        }

    } catch (error) {
        dispatch({ type: SHOP_BOOSTERS_LIST, payload: [] })
        dispatch({ type: LOADER, payload: false })
        // console.log('error in create trans id api::', error)
    }

}
export const getSubscriptionList = () => async (dispatch) => {

    try {
        dispatch({ type: SHOP_SUBSCRIPTION_LIST, payload: [] })
        dispatch({ type: LOADER, payload: true })

        const path = apiPath.get_subscription_list
        const res = await helper.api(path, 'get', {})

        if (res?.success) {
            dispatch({ type: SHOP_SUBSCRIPTION_LIST, payload: res?.results })
            dispatch({ type: LOADER, payload: false })
            return res
        } else {
            dispatch({ type: LOADER, payload: false })
            return false
        }

    } catch (error) {
        dispatch({ type: SHOP_SUBSCRIPTION_LIST, payload: [] })
        dispatch({ type: LOADER, payload: false })
        // console.log('error in create trans id api::', error)
    }

}

export const purchaseSubscription = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        const path = apiPath.purchase_subscription
        const res = await helper.api(path, 'post', payload)

        if (res?.success) {
            dispatch({ type: LOADER, payload: false })
            dispatch(get_profile());
            return res
        } else {
            dispatch({ type: LOADER, payload: false })
            return res
        }

    } catch (error) {
        dispatch({ type: LOADER, payload: false })
        // console.log('error in create trans id api::', error)
    }

}

export const purchaseBooster = (payload) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })

        const path = apiPath.purchase_booster
        const res = await helper.api(path, 'post', payload)

        if (res?.success) {
            dispatch({ type: LOADER, payload: false })
            dispatch(get_profile());
            return res
        } else {
            dispatch({ type: LOADER, payload: false })
            return res
        }

    } catch (error) {
        dispatch({ type: LOADER, payload: false })
        // console.log('error in create trans id api::', error)
    }

}







