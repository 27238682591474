
import { toast } from 'react-toastify';
import apiPath from '../../utils/apiPath'
import helper from '../../utils/helper'
import { LOADER } from '../constants/Auth';
import { CREATE_TRANS_ID } from '../constants/Payment';
import { DEPOSIT_PAYMENT_FAIL, DEPOSIT_PAYMENT_REQUEST, DEPOSIT_PAYMENT_SUCCESS } from '../constants/Other';



var qs = require('qs');

export const createTransId = (data, navigate) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        dispatch({ type: DEPOSIT_PAYMENT_REQUEST, payload: false })

        let payload = { "txn_amount": data?.amount, "payment_type": data?.type, "type":"web" }

        const res = await helper.api(apiPath.depositPayment, 'post', payload)

        if (res?.success) {
            dispatch({ type: DEPOSIT_PAYMENT_SUCCESS, payload: res?.results })
            dispatch({ type: LOADER, payload: false })
            navigate('/deposit-payment')
            return res
        } else {
            toast.warn(res?.msg)
            dispatch({ type: DEPOSIT_PAYMENT_FAIL, payload: false })
            dispatch({ type: LOADER, payload: false })
            return false
        }

    } catch (error) {
        console.log('error in create trans id api::', error)
    }

}


export const updateTrans = (data) => async (dispatch) => {

    try {
        dispatch({ type: LOADER, payload: true })
        let payload = data
        payload = qs.stringify(payload);

        const res = await helper.api(apiPath.updateTransId, 'post', payload)

        if (res?.success) {
            dispatch({
                type: LOADER,
                payload: false
            })
            return res
        } else {
            toast.warn(res?.msg)
            dispatch({
                type: LOADER,
                payload: false
            })
        }

    } catch (error) {
        //console.log('error in create trans id api::', error)
    }

}





