import React from 'react'

const DonationsTab = () => {
  return (
    <div className="standard_booster_list_outer">
      <div className="standard_booster_list"
      // onClick={handleShow}
      >
        <figure>
          <img src="../images/standard_booster.png" alt="" />
          <span className="card_img_overlay">
            <img src="../images/football.png" />
          </span>
        </figure>
        <figcaption>
          <h3>
            <a href="#">Power Striker</a>
          </h3>
          <p>
            It will double the points of the all the strikers
            selected in the team
          </p>
        </figcaption>
      </div>

      <div className="standard_booster_list">
        <figure>
          <img src="../images/standard_booster.png" alt="" />
        </figure>
        <figcaption>
          <h3>
            <a href="#">Power Striker</a>
          </h3>
          <p>
            It will double the points of the all the strikers
            selected in the team
          </p>
        </figcaption>
      </div>
    </div>
  )
}

export default DonationsTab