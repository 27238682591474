import React, { useEffect } from 'react'

import moment from 'moment'
import LeagueRoutes from '../routes/LeagueRoutes'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { get_league_match_details, get_league_states } from '../redux/actions/LeagueAction'
import { useState } from 'react'

const MatchTimer = ({ item, hour, class_name, hr }) => {

  const m3 = moment.unix(item?.timestamp_start !== undefined ? item?.timestamp_start : item?.mathData?.timestamp_start).diff(moment(new Date()), 'seconds')
  // var time = moment.unix(item?.timestamp_start !== undefined ? item?.timestamp_start : item?.mathData?.timestamp_start).diff(moment(new Date(item?.server_time)), 'seconds')


  if (m3 > 0) {
    var day = Math.floor(m3 / 86400)
    var hrs = Math.floor((m3 % 86400) / 3600)
    var mins = Math.floor(((m3 % 86400) % 3600) / 60)
    var secs = Math.floor(((m3 % 86400) % 60) % 60)
  }
  const [[days, hours, minutes, seconds], setTime] = React.useState([parseInt(day), parseInt(hrs), parseInt(mins), parseInt(secs)])
  // const [serverTime, setServerTime] = useState(time)


  const tick = () => {
    const m3 = moment.unix(item?.timestamp_start).diff(moment(new Date()), 'seconds')
    // var time = moment.unix(item?.timestamp_start !== undefined ? item?.timestamp_start : item?.mathData?.timestamp_start).diff(moment(new Date(item?.server_time)), 'seconds')

    if (m3 > 0) {
      const day = Math.floor(m3 / 86400)
      const hrs = Math.floor((m3 % 86400) / 3600)
      const mins = Math.floor(((m3 % 86400) % 3600) / 60)
      const secs = Math.floor(((m3 % 86400) % 60) % 60)
      if (day === 0 && hrs === 0 && mins === 0 && secs === 0) reset()
      else if (hrs === 0 && mins === 0 && secs === 0) { setTime([day - 1, 23, 59, 59]) } else if (mins === 0 && secs === 0) {
        setTime([day, hrs - 1, 59, 59])
      } else if (secs === 0) {
        setTime([day, hrs, mins - 1, 59])
      } else {
        setTime([day, hrs, mins, secs - 1])
      }
    }
  }
 
  const reset = () =>
    setTime([
      parseInt(0),
      parseInt(0),
      parseInt(0),
      parseInt(0)
    ])

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000)
    return () => clearInterval(timerId)
  },)

  if (m3 > 0) {
    var isClass = class_name === 'normal' ? 'normal'
      : class_name === 'active' ? 'match_time active'
        : class_name === 'left_time' ? 'left_time'
          : class_name ? 'match_time' : 'not-started'

    return (
      <>
        {days >= 1
          ? (
            !hour
              ? <div className={isClass}> {days}d  {hr === 'left' && 'left'}</div>
              : <div className={isClass}> {days}d : {hours}h {hr === 'left' && 'left'}</div>
            // <div className={isClass`${hour ? 'not-started' : 'match_time'} `} > {days}d : {hours}h </div>
          )
          : hours === 0
            ? <div className={isClass}> {minutes}m : {seconds}s {hr === 'left' && 'left'}</div>
            : hr === 'left' ? <div className={isClass}> {hours}h : {minutes}m {" "} left</div>
              : hr ? <div className={isClass}> {hours}hr : {minutes}min </div>
                : <div className={isClass}> {hours}h : {minutes}m  </div>}

      </>
    )
  } else return false


  // if (m3 > 0) {
  //   return (
  //     <>
  //       {days >= 1
  //         ? (
  //             !hour
  //               ? <div className={class_name === 'normal' ? 'normal' : class_name ? 'match_time' : 'not-started'}> {days}d  </div>
  //               : <div className={class_name === 'normal' ? 'normal' : class_name ? 'match_time' : 'not-started'}> {days}d : {hours}h </div>
  //           // <div className={`${hour ? 'not-started' : 'match_time'} `} > {days}d : {hours}h </div>
  //           )
  //         : hours === 0
  //           ? <div className={class_name === 'normal' ? 'normal' : class_name ? 'match_time' : 'not-started'}> {minutes}m : {seconds}s</div>
  //           : hr ? <div className={class_name === 'normal' ? 'normal' : class_name ? 'match_time' : 'not-started'}> {hours}hr : {minutes}min</div> : <div className={class_name === 'normal' ? 'normal' : class_name ? 'match_time' : 'not-started'}> {hours}h : {minutes}m</div>}

  //     </>
  //   )
  // } else return false
}

export default MatchTimer
