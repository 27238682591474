import Session from "../../utils/session";
import { GET_SOCCER_BANNERS, GET_LIVE_AND_UPCOMING_MATCHES_SOCCER, GET_SOCCER_MATCHES_LIST, MATCH_DETAIL_SOCCER, GET_CONTEST_LIST_BYMATCH_JSON_SOCCER, FILTER_CONTEST_lIST, JOINED_CONTEST_LIST_SOCCER, PLAYER_TEAM_LIST_SOCCER, CONTEST_DETAILS_SOCCER, TEAM_SCORE_SOCCER, CHECK_WALLET_BALANCE_SOCCER, CONTEST_CONFIRMATION_SOCCER, SELECTED_TEAM_SOCCER, ALL_PLAYER_LIST_SOCCER, STATE_DATA, TEAM_PREVIEW_SOCCER, TABS_ALL_CONTEST_SOCCER, CONTEST_PRIZE_BREAKUP_SOCCER, CONTEST_SHARE_CODE_SOCCER, MATCH_TAB_SOCCER, JOINED_MATCHES_LIST_SOCCER, LIVE_SCORE_SOCCER, SERIES_PLAYER_LIST_SOCCER, TEAM_SELECTED_TAB, LEAGUE_LIST_POP_UP } from "../constants/soccer";




let initialState = {
    live_matches_list: [],
    top_upcoming_matches_list: [],
    completed_matches_list: [],
    upcoming_match_list: [],
    banners_list: [],
    banner_path: '',
    seriesPlayerList: {},
    playerTeamList: {},
    matchDetails: Session.getSession('MATCH_DETAIL_SOCCER') || {},
    contestDetails: Session.getSession('CONTEST_DETAILS_SOCCER') || {},
    joinedContestList: {},
    my_teams: 0,
    my_contests: 0,
    full_contest: 0,
    contestsList: [],
    teamScore: {},
    contest_confirmation_soccer: false,
    selectedTeam: {},
    playerList: [],
    stateData: Session.getSession('STATE_DATA') || {},
    team_preview_soccer: Session.getSession('TEAM_PREVIEW_SOCCER'),
    allContestTabs: 'allContest',
    contestPrizeBreakup: {},
    contestInvitationCode: null,
    selectedTab: 'upcoming',
    joinedMatchesList: {},
    liveScoreDetails: {},

    playerDetails: {},
    myContestTab: 'myContest',
    contestTeamPDF: {},
    contestEntryFee: {},
    checkWalletBalance: {},
    playerList: null,
    playerRoles: null,
    selectedPlayerRoleTab: 'wk-tab',
    teamWithCaptionAndViceCaption: null,



};



const SoccerReducer = function (state = initialState, action) {
    try {
        switch (action.type) {
            case GET_SOCCER_MATCHES_LIST:
                return {
                    ...state,
                    upcoming_match_list: action?.payload?.results?.rows
                };
            case GET_LIVE_AND_UPCOMING_MATCHES_SOCCER:
                return {
                    ...state,
                    live_matches_list: action?.payload?.live,
                    top_upcoming_matches_list: action?.payload?.upcoming,
                    completed_matches_list: action?.payload?.completed,
                };
            case GET_SOCCER_BANNERS:
                // console.log("action",action.payload.banner_path)
                return {
                    ...state,
                    banners_list: action?.payload?.results?.docs,
                    banner_path: action?.payload?.banner_path
                };


            case MATCH_DETAIL_SOCCER:
                Session.setSession('MATCH_DETAIL_SOCCER', action?.payload)
                return {
                    ...state,
                    matchDetails: action?.payload
                };

            case GET_CONTEST_LIST_BYMATCH_JSON_SOCCER:
                return {
                    ...state,
                    my_teams: action?.payload?.my_teams,
                    my_contests: action?.payload?.my_contests,
                    full_contest: action?.payload?.full_contest,
                    contestsList: action?.payload?.results,

                };

            case FILTER_CONTEST_lIST:
                return {
                    ...state,
                    contestsList: action?.payload,

                };

            case JOINED_CONTEST_LIST_SOCCER:
                return {
                    ...state,
                    joinedContestList: action?.payload
                };

            case PLAYER_TEAM_LIST_SOCCER:
                const myTeamIds = state?.contestDetails?.my_teams_ids
                const teamList = action?.payload?.map(team => {
                    if (myTeamIds?.includes(team?._id)) {
                        team.isJoined = true
                    }
                    return team
                })
                return {
                    ...state,
                    playerTeamList: teamList
                };

            case CONTEST_DETAILS_SOCCER:
                Session.setSession('CONTEST_DETAILS_SOCCER', action?.payload)
                return {
                    ...state,
                    contestDetails: action?.payload
                };

            case TEAM_SCORE_SOCCER:
                return {
                    ...state,
                    teamScore: action?.payload
                };
            case CHECK_WALLET_BALANCE_SOCCER:
                return {
                    ...state,
                    checkWalletBalance: action?.payload
                };
            case CONTEST_CONFIRMATION_SOCCER:
                return {
                    ...state,
                    contest_confirmation_soccer: action?.payload
                };
            case SELECTED_TEAM_SOCCER:
                return {
                    ...state,
                    selectedTeam: action?.payload
                };
            // case ALL_PLAYER_LIST_SOCCER:
            //     return {
            //         ...state,
            //         playerList: action?.payload
            //     };
            case STATE_DATA:
                Session.setSession('STATE_DATA', action?.payload)
                return {
                    ...state,
                    stateData: action?.payload
                };
            case TEAM_PREVIEW_SOCCER:
                Session.setSession('TEAM_PREVIEW_SOCCER', action?.payload)
                return {
                    ...state,
                    team_preview_soccer: action?.payload
                };


            case TABS_ALL_CONTEST_SOCCER:
                return {
                    ...state,
                    allContestTabs: action?.payload
                };
            case CONTEST_PRIZE_BREAKUP_SOCCER:
                return {
                    ...state,
                    contestPrizeBreakup: action?.payload
                };
            case CONTEST_SHARE_CODE_SOCCER:
                return {
                    ...state,
                    contestInvitationCode: action?.payload?.results
                };

            case MATCH_TAB_SOCCER:
                return {
                    ...state,
                    selectedTab: action?.payload
                };

            case JOINED_MATCHES_LIST_SOCCER:
                return {
                    ...state,
                    joinedMatchesList: action?.payload
                };

            case LIVE_SCORE_SOCCER:
                return {
                    ...state,
                    liveScoreDetails: action?.payload?.results.sort((a, b) => a._id.inning_number - b._id.inning_number)
                };

                case SERIES_PLAYER_LIST_SOCCER:
                    return {
                        ...state,
                        seriesPlayerList: action?.payload
                    };
              
               
            default:
                return state;
        }
    } catch (error) {
        //console.log('err in reducers==>>>>', error)
    }
};

export default SoccerReducer;