import React from 'react'
import Iframe from '../games/Iframe'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Session from '../../utils/session'
import { toast } from 'react-toastify'
import io from "socket.io-client";
import { get_profile } from '../../redux/actions/AuthActions'


const UltrafairPayment = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isPaymentDeposit, paymentData } = useSelector(state => state.payment)

    const user = Session.getSession("user") || false;

    useEffect(() => {
        console.log('isPaymentDeposit', isPaymentDeposit);
        if (!isPaymentDeposit) {
            navigate(-1)
        }
    }, [])

    // useEffect(() => {
    //     window.addEventListener("message", function (e) {
    //         console.log('main target===========================>>>>>>>', e)
    //         // if (e?.data === 'landscape') {
    //         //     //console.log('device in landscape mode')
    //         //     var target = document.getElementById("iframe-rummy");
    //         //     target.className += "fullScreen";
    //         // }
    //         // if (e?.data === 'portrait') {
    //         //     //console.log('device in portrait mode')
    //         //     var target = document.getElementById("iframe-rummy");
    //         //     target.classList.remove('fullScreen')
    //         // }
    //         // if (e?.data?.event === 'LeaveRummy') {

    //         // }

    //     });
    // }, []);


    // useEffect(() => {
    //     window.open(paymentData?.url, '_blank', 'width=500,height=500');
    // }, [])




    useEffect(() => {
        const socket = io(process.env.REACT_APP_SOCKET_URL);
        socket.off('payment_success').on('payment_success', (data) => {
            console.log('payment_success==>>>>>>', user?._id, data?.user_id);
            if (user?._id === data?.user_id) {
                dispatch(get_profile())
                toast.success('payment success!')
                navigate('/wallet')
            }
        })
        socket.off('payment_failed').on('payment_failed', (data) => {
            if (user?._id === data?.user_id) {
                toast.error('payment Faild!')
                navigate('/wallet')
            }
            console.log('payment_failed ==>>>>>>>', user?._id, data?.user_id);
        })
    }, [])




    return (
        <>
            {
                isPaymentDeposit ? (

                    <iframe src={paymentData?.url} style={{ height: '100vh', width: '100%' }} id="iframe-rummy" />
                ) : (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                )
            }
        </>
    )
}

export default UltrafairPayment

