import { combineReducers } from 'redux'
import LoginReducer from './AuthReduer';
import CommonReducer from './CommonReducer';
import CreateTeamReducer from './createTeamSoccerReducer';
import FantasyReducer from './FantasyReducer';
import OtherReducer from './OtherReducer';
import PaymentReducer from './PaymentRedcers';
import SideBarReducer from './SideBarReducer';
import SoccerReducer from './SoccerReducer';
import LeagueReducer from './LeagueReducer';
import CreateTeamLeagueReducer from './CreateTeamLeagueReducer';
import ShopReducer from './ShopReducer';






const reducers = {
    auth: LoginReducer,
    loader:CommonReducer,
    match:FantasyReducer,
    sidebar:SideBarReducer,
    payment:PaymentReducer,
    static:OtherReducer,
    soccer:SoccerReducer,
    soccerTeam:CreateTeamReducer,
    league:LeagueReducer,
    leagueTeam:CreateTeamLeagueReducer,
    shop:ShopReducer,
};



const appReducer = combineReducers({
 ...reducers
})

export default appReducer;
  
  // // eslint-disable-next-line import/no-anonymous-default-export
  // export default (state, action) => {
  //   const stateCopy = action.type === "LOGOUT" ? undefined : { ...state }
  //   return appReducer(stateCopy, action)
  // }
  