import { useState, useEffect } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'


const RugbyMatchTimer = ({ item, hour, class_name, hr }) => {
    const [loading, setLoading] = useState(true)
    const [serverTime, setServerTime] = useState(null);
    const [[days, hours, minutes, seconds], setTime] = useState([parseInt(0), parseInt(0), parseInt(0), parseInt(0)])


    const fetchServerTime = async () => {
        setLoading(true)
        try {
            const response = await axios.get('http://142.93.99.76:9091/api/fantasy/get-current-timestamp'); // Replace with your server endpoint
            const serverTime = moment.unix(item?.timestamp_start).diff(moment.unix(response.data.results?.currentTimeStamp), 'seconds')
            console.log('res==>>>>>>>>', item?.timestamp_start);
            setServerTime(serverTime);

        } catch (error) {
            console.log('Error fetching server time:', error);
        }
    };



    const reset = () => {
        setTime([
            parseInt(0),
            parseInt(0),
            parseInt(0),
            parseInt(0)
        ])
    }


    const setAllTime = () => {
        if (serverTime > 0) {
            const day = Math.floor(serverTime / 86400)
            const hrs = Math.floor((serverTime % 86400) / 3600)
            const mins = Math.floor(((serverTime % 86400) % 3600) / 60)
            const secs = Math.floor(((serverTime % 86400) % 60) % 60)
            // if (day === 0 && hrs === 0 && mins === 0 && secs === 0) {
            //   reset()
            // } else if (hrs === 0 && mins === 0 && secs === 0) {
            //   setTime([day - 1, 23, 59, 59])
            // } else if (mins === 0 && secs === 0) {
            //   setTime([day, hrs - 1, 59, 59])
            // } else if (secs === 0) {
            //   setTime([day, hrs, mins - 1, 59])
            // } else {
            //   setTime([day, hrs, mins, secs - 1])
            // }
            setTime([day, hrs, mins, secs])
            setServerTime((prevTime) => prevTime - 1);
            // console.log('setTime==>>>', [day, hrs, mins, secs]);
        }
    }

    const tick = () => {
        setAllTime()
    }

    useEffect(() => {
        fetchServerTime();
    }, []);

    useEffect(() => {
        if (serverTime) {
            if (loading) {
                console.log('serverTime', serverTime);
                setAllTime()
                setLoading(false)
            }
        }
    }, [serverTime])





    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000)
        return () => clearInterval(timerId)
    },)


    var isClass = class_name === 'normal' ? 'normal' : class_name === 'active' ? 'match_time active' : class_name === 'left_time' ? 'left_time' : class_name ? 'match_time' : 'time_running'

    if (loading) {
        return <div className={isClass}> {0}h : {0}m {" "} left</div>
    }

    return (

        <>
            {days >= 1
                ? (
                    !hour
                        ? <div className={isClass}> {days}d  {hr === 'left' && 'left'}</div>
                        : <div className={isClass}> {days}d : {hours}h {hr === 'left' && 'left'}</div>
                    // <div className={isClass`${hour ? 'not-started' : 'match_time'} `} > {days}d : {hours}h </div>
                )
                : hours === 0
                    ? <div className={isClass}> {minutes}m : {seconds}s {hr === 'left' && 'left'}</div>
                    : hr === 'left' ? <div className={isClass}> {hours}h : {minutes}m {" "} left</div>
                        : hr ? <div className={isClass}> {hours}hr : {minutes}min </div>
                            : <div className={isClass}> {hours}h : {minutes}m  </div>}

        </>
    )


}

export default RugbyMatchTimer
