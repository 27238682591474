
import React, { useEffect, useState } from 'react'
import CombineRoutes from './routes/CombineRoutes'
import { ToastContainer, toast } from 'react-toastify'

import 'react-tooltip/dist/react-tooltip.css'
import 'slick-carousel/slick/slick.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/afterlogin/style.css'
import './assets/css/afterlogin/responsive.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import './App.css'
import { useSelector } from 'react-redux'
import io from "socket.io-client";
import { useNavigate } from 'react-router-dom'
import Session from './utils/session'

function App() {
  const navigate = useNavigate()


  return (
    <div className='App'>
      <CombineRoutes />
      <ToastContainer icon={<img src="../favicon.ico" className="" />} />
    </div>
  )
}

export default App
