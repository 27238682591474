import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import helper from '../../../utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import LeagueMatchTimer from '../../../utils/LeagueMatchTimer';
import { isEmpty } from 'lodash';
import { PLAYERS_FILTERS_MODEL } from '../../../redux/constants/league';

const LeagueNameHeader = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const startTime = helper.start_time;

    const { match_details, team_preview_soccer, teamScore, leagueStateData } = useSelector(state => state?.league)
    const { filterList, isApplyFilter } = useSelector(state => state?.leagueTeam)

    const pathname = location?.pathname

    const isTopTime = ['/league-create-team', '/league-save-team', "/league-booster-select", "/league-review-team", "/league-transfer-history"]
    const isMiddleTime = ["/league-team-detail"]
    const isMiddleVS = ["", "/league-review-team"]
    const isHome = ['/league-home']
    const isHeader = ['/league-create-team', '/league-save-team', "/league-review-team", "/league-team-detail",]
    const isFilter = ['/league-create-team']
    const isMultiArrow = ['/league-review-team']
    const isMScore = ["/league-team-detail", "/league-save-team"]


    const isDisplayTopTime = isTopTime.includes(pathname)
    const isHomePage = isHome.includes(pathname)
    const isDisplayHeader = isHeader.includes(pathname)
    const isDisplayMiddleTime = isMiddleTime.includes(pathname)
    const isDisplayMiddleVS = isMiddleVS.includes(pathname)
    const isDisplayisFilter = isFilter.includes(pathname)
    const isDisplayMultiArrow = isMultiArrow.includes(pathname)
    const isDisplayMScore = isMScore.includes(pathname)



    // ******************* all function start*****************


    const filterHandler = () => {
        dispatch({ type: PLAYERS_FILTERS_MODEL, payload: true })
    }




    return (
        !isHomePage && <>
            {
                isDisplayTopTime && match_details &&
                <div style={{ marginLeft: '35px', fontSize: '13px' }}>
                    <LeagueMatchTimer item={match_details} hour={true} class_name={false} hr={'left'} />
                </div>
            }

            {
                isDisplayHeader &&

                <div className="middle_soccer_heade pt-2 px-0">

                    {/* {
                        isDisplayisBooster && leagueStateData?.selectedBooster &&
                        <div className="selected_booster" onClick={boosterHandler}>
                            <img style={{ width: '25px' }} src={leagueStateData?.selectedBooster?.image} alt="" />
                        </div>
                    } */}



                    <ul className="d-flex align-items-center justify-content-between list-unstyled mb-0 pb-0 pb-0">
                        <li className="kxip d-flex align-items-center">
                            <figure className="mb-0">
                                <img src={match_details?.localteam_flag} className="logo_image_match" />
                            </figure>
                            {
                                isDisplayTopTime ? (
                                    <h5 className="text-white ps-2 mb-0">{match_details?.localteam_short_name}
                                        {/* ({playerList?.filter((item) => item.isSelected === true && match_details?.localteam_id === item?.team_id).length}) */}
                                    </h5>
                                ) : (
                                    <h5 className="text-white ps-2 mb-0">{match_details?.localteam_short_name}</h5>
                                )
                            }
                        </li>




                        <li>
                            {
                                isDisplayMScore &&
                                <li className="kxip d-flex align-items-center text-green">  Match {match_details?.matchNumber}</li>
                            }


                            {
                                isDisplayMiddleVS &&
                                <div>
                                    <span className='me-1 text-green' style={{ fontSize: '13px' }}>Vs</span>
                                </div>
                            }

                            {/* {
                            isDisplayMiddleTime &&
                            <div>
                                {match_details?.timestamp_start > startTime ?
                                    <div className='d-flex align-items-center' style={{ fontSize: '13px' }}>
                                        <span className='me-1 text-green' style={{ fontSize: '13px' }}>Match 24</span>
                                        <LeagueMatchTimer item={match_details} hour={true} class_name={false} hr={'left'} />
                                    </div>
                                    :
                                    <div className="not-started">
                                        {match_details?.match_status === 'In Progress' ? <strong className='live-status'>Live</strong> : match_details?.match_status}
                                    </div>
                                }
                            </div>
                                 } */}

                            {
                                isDisplayisFilter && <div className='d-flex align-items-center booster_shape view_booster'>
                                    {isApplyFilter && <span className='filter_true'></span>}
                                    <div className="view_booster px-1 fw_600" onClick={filterHandler}>
                                        Filter Players
                                        {/* <img src="./images/league/right_arrow.svg" className='ms-1' alt="" /> */}
                                    </div>
                                </div>
                            }


                        </li>

                        <li className="rcbT d-flex align-items-center">
                            {
                                isDisplayTopTime ? (
                                    <h5 className="text-white ps-2 mb-0 fs-16">{match_details?.visitorteam_short_name}
                                        {/* ({playerList?.filter((item) => item.isSelected === true && match_details?.visitorteam_id === item?.team_id).length}) */}
                                    </h5>
                                ) : (
                                    <h5 className="text-white ps-2 mb-0 fs-16">{match_details?.visitorteam_short_name}</h5>
                                )
                            }
                            <figure className="mb-0">
                                <img src={match_details?.visitorteam_flag} className="logo_image_match" />
                            </figure>
                        </li>
                    </ul>

                </div>

            }

            {
                isDisplayMiddleTime &&
                <div className="middle_soccer_heade  px-0">
                    <ul className="d-flex align-items-center justify-content-between list-unstyled mb-0 pb-0 pb-0">
                        <li className="rcbT d-flex align-items-center"> </li>
                        <li className="rcbT d-flex align-items-center text-white " style={{ fontSize: '12px' }}>
                            <LeagueMatchTimer item={match_details} hour={true} class_name={'left_time'} hr={'left'} />
                        </li>
                        <li className="rcbT d-flex align-items-center"></li>
                    </ul>
                </div>
            }

            {
                isDisplayMultiArrow &&
                <div className="middle_soccer_heade pt-2 px-0">
                    <ul className="d-flex align-items-center justify-content-between list-unstyled mb-0 pb-0 pb-0">
                        <li className="rcbT d-flex align-items-center">Last Match </li>
                        <li className="rcbT d-flex align-items-center">
                            <img src="/images/league/transfer_green.svg" alt='' />
                        </li>

                        <li className="rcbT d-flex align-items-center">Next Match</li>
                    </ul>
                </div>
            }
            {/* {
                isDisplayMScore &&
                <div className="middle_soccer_heade pt-2 px-0 leaderboard_header">
                    <ul className="d-flex align-items-center justify-content-between list-unstyled mb-0 pb-0 pb-0">
                        <li className="rcbT d-flex align-items-center fs-16">180/10 (20) </li>
                        <li className="text-center fs-12 fw-normal text-green top-minus">  Match {match_details?.matchNumber}</li>
                        <li className="rcbT fs-16 text-end">164/5 (19.1)</li>
                    </ul>
                </div>
            } */}


        </>
    )
}

export default LeagueNameHeader