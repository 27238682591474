import React from 'react'

const RewardsTab = () => {
    return (
        <div className="comingsoon_wrap">
            <img
                src="images/comingsoon.png"
                className="comingsoon_wrap_banner"
                alt=""
            />
            <div className="coming_soon_caption">
                <strong>Coming soon...</strong>
                <p>
                    Follow the news and be <br />
                    the first to know
                </p>
            </div>
        </div>
    )
}

export default RewardsTab