import React from 'react'
import helper from '../../utils/helper'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { useEffect } from 'react'
import { depositPayment } from '../../redux/actions/paymentActions'

const WalletHeader = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)


    const payment = () => {
        navigate('/addcash')
    }



    if (location?.pathname !== '/wallet') {
        document.getElementById("outlet-container")?.classList?.remove('wallet_scroll_page')
        return ''
    }

    if (location?.pathname === '/wallet') {
         document.getElementById("outlet-container")?.classList?.add('wallet_scroll_page')
        // console.log('target==>>>', target);
    }



    return (
        <div className="wallet_header mt-4">
            <div className="text-center">
                <div className="d-flex text-white justify-content-center align-items-center">
                    <img src="./images/wallet_icon.png" alt="" />
                    <h3 className="m-0 ms-2">
                        {/* <USDT /> {" "} {user?.total_balance?.toLocaleString()}  */}
                        {helper.localizeNumber('$', user?.total_balance)}
                    </h3>
                </div>

                <h4 className="mt-2 mb-3">Total Available Balance</h4>
                <Button type="submit" className="btn-green btn btn-primary w-100 mx-1 fw_700" onClick={payment}>
                    {/* <img src="./images/create-team.svg" alt="" className="me-2" /> */}
                    Deposit
                </Button>
            </div>
        </div>
    )
}

export default WalletHeader