import Session from "../../utils/session";
import { ALL_PLAYER_LIST_LEAGUE, APPLY_PLAYERS_FILTERS, EDIT_TEAM_LEAGUE, PLAYERS_FILTERS_MODEL, PLAYERS_FILTERS_RESET, PLAYERS_FILTERS_SELECT, PLAYER_DEC_LEAGUE, PLAYER_INC_LEAGUE, RESET_PLAYER_LEAGUE, SELECT_3X_BOOSTED_PLAYER, SELECT_CAPTAIN_LEAGUE, SELECT_VISE_CAPTAIN_LEAGUE, SET_PLAYERS_FILTERS_LIST, TEAM_SELECTED_TAB_LEAGUE, TEAM_TYPE_LEAGUE } from "../constants/league";



const soccerRoles = [
    { role: 'Goalkeeper', min: 1, max: 1, selected: 0, remain: 1 },
    { role: 'Defender', min: 3, max: 5, selected: 0, remain: 3 },
    { role: 'Midfielder', min: 3, max: 5, selected: 0, remain: 3 },
    { role: 'Forward', min: 1, max: 3, selected: 0, remain: 1 },
]
const cricketRoles = [
    { role: 'Batsman', min: 3, max: 6, selected: 0, remain: 3 },
    { role: 'Bowler', min: 3, max: 6, selected: 0, remain: 3 },
    { role: 'Allrounder', min: 1, max: 4, selected: 0, remain: 1 },
    { role: 'Wicketkeeper', min: 1, max: 4, selected: 0, remain: 1 },
]



const team_data = Session.getSession('team_data')




let initialState = {
    teamTsk: {},
    teamType: team_data?.teamType || '',
    playerList: team_data?.playerList || [],
    selected_player_count: team_data?.selected_player_count || 0,
    playerRoles: team_data?.playerRoles || [],
    team_selected_tab: team_data?.team_selected_tab || 'wk-tab',
    playerTeamCount: team_data?.playerTeamCount || 0,

    oldTeam: team_data?.oldTeam || [],
    newTeam: team_data?.playerTeamCount || [],
    usedTransfer: team_data?.usedTransfer || 0,
    availableTransfer: team_data?.availableTransfer || 0,

    isPlayerFilter: false,
    isApplyFilter: false,
    filterList: []

};




const CreateTeamLeagueReducer = function (state = initialState, action) {

    const setLocalTeamData = () => {
        setTimeout(() => {
            const newData = {
                teamTsk: { name: state?.teamTsk },
                teamType: state?.teamType,
                playerList: state?.playerList,
                selected_player_count: state?.selected_player_count + 1,
                playerRoles: state?.playerRoles,
                team_selected_tab: state?.team_selected_tab,
                playerTeamCount: state?.playerTeamCount,

                oldTeam: state?.oldTeam || [],
                newTeam: state?.playerTeamCount || [],
                usedTransfer: state?.usedTransfer || 0,
                availableTransfer: state?.availableTransfer || 0,

            }
            Session.setSession('team_data', newData)
        }, 1000);
    }

    try {
        switch (action.type) {

            case ALL_PLAYER_LIST_LEAGUE:

                Session.clearSession('team_data')
                const Roles = action?.payload?.homeActiveKey === 'soccer' ? soccerRoles : cricketRoles

                return {
                    ...state,
                    playerTeamCount: action.payload?.playerTeamCount,
                    selected_player_count: 0,
                    playerList: action.payload?.results?.map(player => {
                        player.isSelected = false
                        player.isCaptain = false
                        player.isViceCaptain = false
                        player.isHidden = false
                        return player
                    }),
                    playerRoles: JSON.parse(JSON.stringify(Roles)),
                    team_selected_tab: 'wk-tab',
                    isApplyFilter: false

                };

            case EDIT_TEAM_LEAGUE:


                const playerData = action?.payload
                const { total_goalkeeper, total_defender, total_midfielder, total_forward, boosterCode, boostedPlayer } = playerData
                const playerIds = playerData?.seriesPlayer
                const captain_player_id = playerData?.captain_player_id
                const vice_captain_player_id = playerData?.vice_captain_player_id



                const soccerEditRoles = [
                    { role: 'Goalkeeper', min: 1, max: 1, selected: total_goalkeeper, remain: 0 },
                    { role: 'Defender', min: 3, max: 5, selected: total_defender, remain: 0 },
                    { role: 'Midfielder', min: 3, max: 5, selected: total_midfielder, remain: 0 },
                    { role: 'Forward', min: 1, max: 3, selected: total_forward, remain: 0 },
                ]
                const cricketEditRoles = [
                    { role: 'Batsman', min: 3, max: 6, selected: playerData?.total_batsman, remain: 0 },
                    { role: 'Bowler', min: 3, max: 6, selected: playerData?.total_bowler, remain: 0 },
                    { role: 'Allrounder', min: 1, max: 4, selected: playerData?.total_allrounder, remain: 0 },
                    { role: 'Wicketkeeper', min: 1, max: 4, selected: playerData?.total_wicketkeeper, remain: 0 },
                ]
                const editRoles = action?.payload?.homeActiveKey === 'soccer' ? soccerEditRoles : cricketEditRoles

                state.usedTransfer = playerData?.playerTransferInMatch
                state.availableTransfer = playerData?.available_transfer
                state.oldTeam = playerData?.seriesPlayer



                setLocalTeamData()

                return {
                    ...state,

                    selected_player_count: 11,
                    playerList: state?.playerList?.map(player => {

                        if (playerIds?.includes(player?.player_id)) {
                            player.isSelected = true
                        }
                        if (captain_player_id == player?.player_id) {
                            player.isCaptain = true
                        }
                        if (vice_captain_player_id == player?.player_id) {
                            player.isViceCaptain = true
                        }

                        if (boosterCode == 3 && boostedPlayer?.includes(player?.player_id)) {
                            player.isBoosted3x = true
                        }

                        return player
                    }),
                    playerRoles: editRoles,


                };

            case PLAYER_INC_LEAGUE:
                var item = action?.payload;

                const isTransfer = state?.oldTeam?.filter(id => id === item?.player_id)?.length

                if (!isTransfer) {
                    state.usedTransfer += 1
                }

                if (state?.selectedPlayerCount !== 11) {
                    const newPlayers = state?.playerList.filter(player => {
                        if (player.player_id === item?.player_id) {
                            player.isSelected = true
                        }
                        return player;
                    })

                    var playerRoles = state?.playerRoles.map(ele => {
                        if (ele.role === item?.player_role) {
                            ele.selected += 1
                            if (ele.remain != 0) {
                                ele.remain -= 1
                            }
                        }
                        return ele
                    })
                    setLocalTeamData()

                    return {
                        ...state,
                        selected_player_count: state?.selected_player_count + 1,
                        playerRoles: playerRoles,
                        playerList: newPlayers

                    };

                } else {
                    return {
                        ...state,
                    };
                }

            case PLAYER_DEC_LEAGUE:
                var item = action?.payload;

                const isTransfers = state?.oldTeam?.filter(id => id === item?.player_id)?.length

                if (!isTransfers) {
                    state.usedTransfer -= 1
                }


                const newPlayers = state?.playerList.filter(player => {
                    if (player.player_id === item?.player_id) {
                        player.isSelected = false
                        player.isCaptain = false
                        player.isViceCaptain = false
                        player.isBoosted3x = false
                    }
                    return player;
                })

                var playerRoles = state?.playerRoles.map(ele => {
                    if (ele.role === item?.player_role) {
                        if (ele.selected === ele.min) {
                            ele.remain += 1
                        }
                        ele.selected -= 1
                    }
                    return ele
                })
                setLocalTeamData()
                return {
                    ...state,
                    selected_player_count: state?.selected_player_count - 1,
                    playerList: newPlayers,
                    playerRoles: playerRoles,
                };


            case RESET_PLAYER_LEAGUE:
                const resetRoles = action?.payload === 'soccer' ? soccerRoles : cricketRoles
                setLocalTeamData()
                return {
                    ...state,
                    selected_player_count: 0,
                    playerRoles: JSON.parse(JSON.stringify(resetRoles)),
                    playerList: state?.playerList?.map(player => {
                        player.isSelected = false
                        player.isCaptain = false
                        player.isViceCaptain = false
                        return player
                    })
                };


            case SELECT_CAPTAIN_LEAGUE:
                const captainId = action?.payload;

                const newPlayerCaptain = state?.playerList?.map((player, i) => {
                    if (captainId === player?.player_id && player.isViceCaptain === true) {
                        player.isViceCaptain = false
                    }

                    if (captainId === player?.player_id) {
                        player.isCaptain = !player?.isCaptain
                        player.isBoosted3x = false
                    } else {
                        player.isCaptain = false
                    }
                    return player;
                })

                return {
                    ...state,
                    playerList: newPlayerCaptain

                };

            case SELECT_VISE_CAPTAIN_LEAGUE:
                const viseCaptainId = action?.payload;

                const newPlayerViseCaptain = state?.playerList?.map((player, i) => {
                    if (viseCaptainId === player?.player_id && player.isCaptain === true) {
                        player.isCaptain = false
                    }
                    if (viseCaptainId === player?.player_id) {
                        player.isViceCaptain = !player?.isViceCaptain
                        player.isBoosted3x = false
                    } else {
                        player.isViceCaptain = false
                    }

                    return player;
                })

                return {
                    ...state,
                    playerList: newPlayerViseCaptain
                };

            case SELECT_3X_BOOSTED_PLAYER:
                const isBoostedId = action?.payload;
                const newBoostedPlayer = state?.playerList?.map((player, i) => {
                    if (isBoostedId === player?.player_id) {
                        player.isCaptain = false
                        player.isViceCaptain = false
                        player.isBoosted3x = true
                    } else {
                        player.isBoosted3x = false
                    }
                    return player;
                })

                return {
                    ...state,
                    playerList: newBoostedPlayer
                };

            case TEAM_SELECTED_TAB_LEAGUE:
                return {
                    ...state,
                    team_selected_tab: action?.payload
                };

            case TEAM_TYPE_LEAGUE:
                return {
                    ...state,
                    teamType: action?.payload
                };


            case SET_PLAYERS_FILTERS_LIST:
                const filterPlayers = Array.from(new Set(state?.playerList.map((player) => player.team_short_name)))?.map(item => ({ name: item, isSelected: false }))
                return {
                    ...state,
                    filterList: filterPlayers
                };
            case PLAYERS_FILTERS_MODEL:
                return {
                    ...state,
                    isPlayerFilter: action?.payload
                };

            case PLAYERS_FILTERS_SELECT:
                const selectedFilter = state?.filterList?.filter(item => {
                    if (item.name === action?.payload?.name) {
                        item.isSelected = !item.isSelected
                    }
                    return item
                })
                return {
                    ...state,
                    filterList: selectedFilter
                };



            case APPLY_PLAYERS_FILTERS:
                return {
                    ...state,
                    playerList: state.playerList?.map(player => {
                        if (!action?.payload?.includes(player?.team_short_name)) {
                            player.isHidden = true
                        } else {
                            player.isHidden = false
                        }
                        return player
                    }),
                    isPlayerFilter: !state?.isPlayerFilter,
                    isApplyFilter: true
                };

            case PLAYERS_FILTERS_RESET:

                return {
                    ...state,
                    filterList: state?.filterList?.filter(item => {
                        item.isSelected = false
                        return item
                    }),
                    playerList: state.playerList?.map(player => {
                        player.isHidden = false
                        return player
                    }),
                    isApplyFilter: false
                };

            default:
                return state;
        }
    } catch (error) {
        console.log('err in create team soccer reducer==>>>>', error)
    }
};

export default CreateTeamLeagueReducer;



