import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BOOSTER_SHOP_TABS } from '../../../redux/constants/Shop'
import { useEffect } from 'react'
import { getBoosterListByCategoryAndSubCategory, purchaseBooster } from '../../../redux/actions/ShopActions'
import helper from '../../../utils/helper'
import { Button, Offcanvas } from 'react-bootstrap'
import { useState } from 'react'
import { toast } from 'react-toastify'

const StandardBoosters = () => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [quantity, setQuantity] = useState(1)

    const { standardBooster, shopBoosterList } = useSelector(state => state.shop)



    const getBoosterList = async (item) => {
        dispatch(getBoosterListByCategoryAndSubCategory(standardBooster))
    }


    useEffect(() => {
        if (standardBooster) {
            getBoosterList()
        }
    }, [])

    const handleShow = (item) => {
        setShow(item)
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleInc = () => {
        setQuantity(prev => prev + 1)
    }
    const handleDec = () => {
        setQuantity(prev => prev >= 2 ? prev - 1 : 1)
    }

    const purchase = async () => {

        const payload = {
            "boosterId": show?.id,
            "boosterQuantity": quantity
        }
        const res = await dispatch(purchaseBooster(payload))
        if (res?.success) {
            toast.success(res?.msg)
        } else {
            toast.error(res?.msg)
        }

        setQuantity(1)
        setShow(false)
    }


    return (
        <>
            <div className="standard_booster_head pt-3">
                <h2 className="text-white">{standardBooster?.name}</h2>
            </div>

            <div className="standard_booster_list_outer">
                {
                    shopBoosterList && shopBoosterList?.length > 0 && shopBoosterList?.map((item, i) => (
                        <div className="standard_booster_list cursor"
                            onClick={() => handleShow(item)}
                        >
                            <figure>
                                <img src={item?.booster_image} />
                                <span className="card_img_overlay">
                                    <img src="../images/football.png" />
                                </span>
                            </figure>
                            <figcaption>
                                <h3>
                                    <a href="#">{item?.title}</a>
                                </h3>
                                <p>{helper.textConverter(item?.description, 45)}</p>

                                <div className="card_footer">
                                    <span>{item?.subCategoryName}</span>
                                    <div className="standard_value">
                                        <span>{item?.price}</span>
                                        <img src="../images/standard_logo.png" alt="" />
                                    </div>
                                </div>
                            </figcaption>
                        </div>
                    ))
                }



            </div>


            {/* offcanvas */}

            <Offcanvas
                show={show}
                onHide={handleClose}
                placement="bottom"
                className="theme_offcanvas"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='text-dark fw_700'>Purchase confirmation</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="pffcanvas_data">
                        <div className="banner_wrap">
                            {/* <img src="../images/offcanvas_banner.png" /> */}
                            <img src={show?.booster_image} alt='' className='popup_image' />
                            <div className="ofcanvas_play_count">
                                <span>{show?.title}</span>
                                <div className="playing_count">
                                    <img src="../images/standard_logo.png" />
                                    <span>{show?.price}</span>
                                </div>
                            </div>
                        </div>

                        <div className="fix_article">
                            <h3 className='text-dark fw_700'>Elite Booster</h3>
                            <p className='text-dark'>{show?.description}</p>
                        </div>

                        <div className="quantity_main">
                            <strong className='text-dark fw_700'>Quantity</strong>

                            <div className="quantity">
                                <span onClick={handleDec}>
                                    <img src="../images/minus.svg" />
                                </span>
                                {/* <input type="number" value={quantity} /> */}
                                <strong className='ms-4 me-4 fw_700 text-dark'>{quantity}</strong>
                                <span onClick={handleInc} >
                                    <img src="../images/plush.svg" />
                                </span>
                            </div>
                        </div>

                        <div className="subtotal">
                            <strong className='text-dark fw_700'>Subtotal</strong>

                            <div className="playing_count">
                                <img src="../images/standard_logo.png" />
                                <span className='text-dark fw_700'>{(show?.price * quantity)}</span>
                            </div>
                        </div>

                        <Button className="booster_theme_btn my-3" onClick={purchase}>Confirm</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>


        </>
    )
}

export default StandardBoosters