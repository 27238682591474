import React, { useEffect } from "react";
import LeftPanel from "../components/LeftPanel";
import RightPanel from "../components/RightPanel";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import CommonBottom from "./CommonBottom";
import Header from "../pages/session_leguage/component/Header";
import Footer from "../pages/session_leguage/component/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import LeagueDailogs from "../pages/session_leguage/component/LeagueDailogs";
import moment from "moment";
import { get_league_match_details, get_league_states } from "../redux/actions/LeagueAction";
import { toast } from "react-toastify";
import { useState } from "react";
import ServerTime from "./ServerTime";
import { LEAGUE_MY_TEAMS_ACCORDION } from "../redux/constants/league";


const LeagueLayout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const loader = useSelector((state) => state?.loader?.loader);
  const team_loader = useSelector((state) => state?.loader?.team_loader);
  const { match_details, leagueDetails, league_states } = useSelector(state => state?.league)


  const getLeagueMatchDetails = async () => {
    const payload = { series_id: leagueDetails?.id_api }
    dispatch(get_league_states(payload))
    dispatch(get_league_match_details(payload))
  }

  useEffect(() => {
    getLeagueMatchDetails()
  }, [])

  useEffect(() => {
    if (league_states?.userTeams?.length > 0) {
      dispatch({ type: LEAGUE_MY_TEAMS_ACCORDION, payload: league_states?.userTeams[0] })
    }
  }, [league_states])

  return (
    <>
      {/* <ServerTime /> */}
      {/* <MyComponent /> */}
      <div
        className={`main-wrapper-outer 
         ${loader || team_loader ? "loader" : ""}`}
      >
        {(loader || team_loader) && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}

        <div className="AL_body">
          <div className="AL_wrap2 h-100">
            <div className="d-flex h-100">
              <LeftPanel />

              <div className="middle_panel">
                <div className="middlePage_bg h-100">
                  <Header />

                  <div className="" id="">
                    <Outlet />
                  </div>

                  <Footer />
                </div>
              </div>

              <RightPanel />
            </div>
          </div>
        </div>
      </div>

      <LeagueDailogs />
    </>
  );
};

export default LeagueLayout;
