import React from 'react'
import { Button, Form, Modal, Offcanvas, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { APPLY_PLAYERS_FILTERS, CONTEST_CONFIRMATION_LEAGUE, EXITS_CREATE_TEAM, INFO_POP_UP, LEAGUE_STATE_DATA, LOW_BALANCE_LEAGUE, PLAYERS_FILTERS_MODEL, PLAYERS_FILTERS_RESET, PLAYERS_FILTERS_SELECT, TEAM_EXITS } from '../../../redux/constants/league'
import USDT from '../../../components/USDT'
import { Tooltip as ReactTooltip } from "react-tooltip";
import helper from '../../../utils/helper'
import { join_contest_confirm_league } from '../../../redux/actions/LeagueAction'
import { replace } from 'lodash'
import { useEffect } from 'react'

const LeagueDailogs = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const { infoPopUp, match_details, isContestConfirm, walletBalance, lowBalance, teamExits, isExitsCreateTeam } = useSelector(state => state?.league)
    const { isPlayerFilter, playerList, filterList } = useSelector(state => state?.leagueTeam)

    const handleOff = () => {
        dispatch({ type: INFO_POP_UP, payload: false })
    };
    const handleOn = () => {
        dispatch({ type: INFO_POP_UP, payload: true })
    };

    const closeContestConfirmation = () => {
        dispatch({
            type: CONTEST_CONFIRMATION_LEAGUE,
            payload: false,
        });
    };


    const handleJoinContest = async () => {
        dispatch(join_contest_confirm_league(navigate))
    };

    const closeBalancePopUp = () => {
        dispatch({
            type: LOW_BALANCE_LEAGUE,
            payload: false
        })
    }
    const closeTeamExitsPopUp = () => {
        dispatch({
            type: TEAM_EXITS,
            payload: false
        })
    }

    const exitsTeam = () => {
        dispatch({ type: EXITS_CREATE_TEAM, payload: false })
        navigate(-1, { state: location?.state, replace: true })
        dispatch({ type: LEAGUE_STATE_DATA, payload: {} })
    }

    const continueTeam = () => {
        dispatch({ type: EXITS_CREATE_TEAM, payload: false })
    }



    // players filters model **************

    const playerFilters = () => {
        dispatch({ type: PLAYERS_FILTERS_MODEL, payload: !isPlayerFilter })
    }

    const resetFilter = (item) => {
        dispatch({ type: PLAYERS_FILTERS_RESET, payload: true })
    }
    const handleSelectFilter = (item) => {
        dispatch({ type: PLAYERS_FILTERS_SELECT, payload: item })
    }
    const applyPlayersFilter = () => {
        const filterName = filterList?.filter(item => item.isSelected)?.map(item => item.name)
        dispatch({ type: APPLY_PLAYERS_FILTERS, payload: filterName })
    }



    return (
        <div className=" position-relative ">
            <Offcanvas show={infoPopUp} onHide={handleOff} placement={"bottom"} className="offcanvas-common contest-canvas dark_theme_canvas ">

                <Offcanvas.Header className="info_top_header">
                    <div className="text-center d-flex justify-content-between align-items-center filter-heading total Winners">
                        <div>
                            <img
                                onClick={() => handleOff()}
                                src="./images/top_arrow.png"
                                className="me-3 cursor"
                                alt=""
                            />{" "}
                            <span style={{ fontSize: '18px', fontWeight: 600, color: '#fff' }}  > {match_details?.series_name}</span>
                        </div>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Header closeButton className="bg-dark info_middle_header close_design">
                    <div className="text-center d-flex justify-content-between align-items-center filter-heading total Winners">
                        <div>
                            <span style={{ fontSize: '18px', fontWeight: 600, color: '#fff' }}  > Making Transfer</span>
                        </div>
                    </div>
                </Offcanvas.Header>

                <Offcanvas.Body className='bg-dark'>
                    <div className='info_points_div'>
                        <img src="./images/info_image.png" alt="" />
                        <ul>
                            <li> <span>1</span> Tap on '+' to add a player in your team.</li>
                            <li> <span>2</span> Tap on '-' to remove a player from your team.</li>
                            <li> <span>3</span> Tap here to view your transfer history.</li>
                            <li> <span>4</span> Number of transfer you can make.</li>
                            <li> <span>5</span> Tap here to apply a booster.</li>
                        </ul>
                    </div>


                </Offcanvas.Body>
            </Offcanvas>


            {/* Contest join confirmation */}
            <Modal
                show={isContestConfirm}
                onHide={closeContestConfirmation}
                className="contest_confirmation dark_theme_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>CONFIRMATION </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <span style={{ fontSize: "12px" }}>
                        {/* Remaining Balance (excluding Bonus) = <USDT /> */}
                        Unutilized Balance + Winnings =
                        {helper?.localizeNumber('$', (walletBalance?.cash_balance + walletBalance?.winning_balance))}
                    </span>
                    <Table className="contest_confirmation_table mt-2">
                        <tbody>
                            <tr>
                                <td>Entry</td>
                                <td>
                                    {helper?.localizeNumber('$', (walletBalance?.entry_fee))}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: "700" }}>
                                    Usable Cash Bonus{" "}
                                    <img
                                        src="./images/info.png"
                                        alt=""
                                        id="bonus-tooltip"
                                        style={{ maxWidth: "17px", maxHeight: "17px" }}
                                    />
                                </td>
                                <td className={walletBalance?.usable_bonus == 0 ? "" : "text-danger"} >
                                    {helper?.localizeNumber('$', (walletBalance?.usable_bonus))}
                                </td>
                            </tr>

                            <tr className="total_value">
                                <td>
                                    <b>To Pay</b>
                                </td>
                                <td>
                                    <b>
                                        {helper?.localizeNumber('$', (walletBalance?.entry_fee - walletBalance?.usable_bonus))}
                                    </b>
                                </td>
                            </tr>
                        </tbody>
                        <ReactTooltip
                            anchorId={`bonus-tooltip`}
                            place="bottom"
                            variant="info"
                            style={{ fontSize: "8px" }}
                            content={() => (
                                <>
                                    Max 10% of total entry fee* per match *valid for selected
                                    contests <br /> only *not valid for private contests
                                </>
                            )}
                        />
                    </Table>

                </Modal.Body>

                <Modal.Footer>
                    <div className="w-100">
                        <Button
                            variant="primary"
                            onClick={() => handleJoinContest()}
                            className="border-0 w-100"
                        >
                            Join Contest
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* Contest join confirmation */}



            {/* low balancer confirmation */}
            <Modal show={lowBalance} onHide={closeBalancePopUp} className="contest_confirmation contest_confirmation_popup ">
                <Modal.Header closeButton className='modal-header'>
                    {/* <Modal.Title>CONFIRMATION</Modal.Title> */}
                    <div className='modal-title'>
                        <img src='../images/logo.png' alt='' height={'auto'} width={'130px'} />
                    </div>
                </Modal.Header>


                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {/* <p className="text-white">Low balance! Please add <USDT /> {Math.ceil(walletBalance?.entry_fee - (walletBalance?.cash_balance + walletBalance?.winning_balance))} to join contest</p> */}
                    <p className="text-white">Low balance! Please add {helper?.localizeNumber('$', (Number(walletBalance?.entry_fee) - Number(walletBalance?.total_balance)))} to join contest</p>
                </Modal.Body>


                <Modal.Footer>
                    <div className="w-100 confirm-box">
                        <Button variant="secondary" onClick={() => closeBalancePopUp()} className="border-0 w-100 bg-orange" style={{ background: '#fb6d31' }}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => closeBalancePopUp()} className="border-0 w-100 ms-10">
                            Ok
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>


            {/* Team already exits */}
            <Modal show={teamExits} onHide={closeTeamExitsPopUp} className="contest_confirmation contest_confirmation_popup team_exits_dialog  ">

                <Modal.Body className='bg-dark'>

                    <div className='team_exits_body px-4'>

                        <figure>
                            <img src='../images/logo.png' alt='' height={'auto'} />
                        </figure>

                        <span className="text-white">You are already have 1 unjoined team, First join with team</span>


                        <Button variant="primary" onClick={() => closeTeamExitsPopUp()} className="border-0 px-4 mb-0">
                            Ok
                        </Button>

                    </div>
                </Modal.Body>

            </Modal>


            {/* exits from team making process */}
            <Modal show={isExitsCreateTeam} onHide={closeTeamExitsPopUp} className="contest_confirmation contest_confirmation_popup team_exits_dialog  ">

                <Modal.Body className='bg-dark'>

                    <div className='team_exits_body px-4'>

                        <figure>
                            <img src='../images/logo.png' alt='' height={'auto'} />
                        </figure>

                        <span className="text-white">Are you sure, you want to discard team?</span>

                        <div className="w-100 confirm-box">
                            <Button variant="danger" onClick={() => continueTeam()} className="border-0 w-100 bg-orange text-white" style={{ background: 'rgb(216 77 37)' }}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={() => exitsTeam()} className="border-0 w-100 ms-10">
                                Ok
                            </Button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>


            {/* create team players filters list */}
            <div className=" position-relative bg-dark h-100">
                <Offcanvas show={isPlayerFilter} onHide={playerFilters} placement={"bottom"} className="offcanvas-common contest-canvas dark_theme_canvas filter_list_dialog_canvas ">
                    <Offcanvas.Header className="">
                        <div className="text-center d-flex justify-content-between align-items-center filter-heading total Winners">
                            <div className="filter_list_dialog">
                                <img
                                    onClick={() => playerFilters()}
                                    src="./images/close.png"
                                    className="me-3 cursor"
                                    width={'15px'}
                                    alt=""
                                />{" "}
                                <span style={{ fontSize: '18px', fontWeight: 600, color: '#fff', }}   > Filter players</span>
                                <span style={{ fontSize: '18px', fontWeight: 600, color: '#11FFBD', cursor: 'pointer' }} onClick={resetFilter} > Reset</span>
                            </div>
                        </div>
                    </Offcanvas.Header>

                    <Offcanvas.Body >

                        <div>
                            {
                                filterList && filterList?.length > 0 && filterList?.map((item, i) => (
                                    <div className="league_match player_filter_list_div" key={i}
                                    // onClick={() => selectFilter(item)}
                                    >
                                        <h6 className="text-white ps-2 mb-0">{item?.name}</h6>
                                        <div style={{ display: 'flex' }} className="mb-3 filter-list-check align-items-center" >
                                            <span className="booster_check">
                                                <Form.Check
                                                    type='checkbox'
                                                    placeholder="T&C"
                                                    checked={item?.isSelected}
                                                    onChange={(e) => handleSelectFilter(item)}
                                                    style={{ accentColor: 'green' }}
                                                    className="cursor signup-checkbox "
                                                    width='10px'
                                                    height='10px'
                                                />
                                            </span>
                                        </div>
                                        {/* { item?.id_api === params && <span className="booster_check"><img src="./images/league/booster_check.svg" /></span> } */}
                                    </div>
                                ))
                            }
                        </div>

                        <div className="league_btn d-flex mt-4 pt-4 sticky_button">
                            <button
                                type="submit"
                                className="btn-green btn btn-primary w-100 mx-1 btn btn-primary fw_700"
                                onClick={applyPlayersFilter}
                                disabled={!filterList?.filter(item => item?.isSelected)?.length}
                            >
                                {/* <img src="./images/create-team.svg" alt="" className="me-2" /> */}
                                APPLY
                            </button>
                        </div>

                    </Offcanvas.Body>
                </Offcanvas>
            </div>




        </div>
    )
}

export default LeagueDailogs