export const LEAGUE_DETAILS = 'LEAGUE_DETAILS'
export const GET_SERIES_LIST = 'GET_SERIES_LIST'
export const GET_LEAGUE_MATCH_DETAILS = 'GET_LEAGUE_MATCH_DETAILS'
export const GET_LEAGUE_STATES = 'GET_LEAGUE_STATES'
export const GET_LEAGUE_BOOSTER_LIST = 'GET_LEAGUE_BOOSTER_LIST'
export const LEAGUE_STATE_DATA = 'LEAGUE_STATE_DATA'




// *****************************team creation content******************************
export const ALL_PLAYER_LIST_LEAGUE = 'ALL_PLAYER_LIST_LEAGUE'
export const TEAM_TYPE_LEAGUE = 'TEAM_TYPE_LEAGUE'

export const EDIT_TEAM_LEAGUE = 'EDIT_TEAM_LEAGUE'
export const PLAYER_DEC_LEAGUE = 'PLAYER_DEC_LEAGUE'
export const PLAYER_INC_LEAGUE = 'PLAYER_INC_LEAGUE'
export const RESET_PLAYER_LEAGUE = 'RESET_PLAYER_LEAGUE'
export const SELECT_CAPTAIN_LEAGUE = 'SELECT_CAPTAIN_LEAGUE'
export const SELECT_VISE_CAPTAIN_LEAGUE = 'SELECT_VISE_CAPTAIN_LEAGUE'
export const TEAM_SELECTED_TAB_LEAGUE = 'TEAM_SELECTED_TAB_LEAGUE'
export const LEAGUE_MY_TEAMS_ACCORDION = 'LEAGUE_MY_TEAMS_ACCORDION'
export const SELECT_3X_BOOSTED_PLAYER = 'SELECT_3X_BOOSTED_PLAYER'




// *****************************Contest constant******************************
export const LEAGUE_ALL_CONTEST = 'LEAGUE_ALL_CONTEST'
export const LEAGUE_MY_CONTEST = 'LEAGUE_MY_CONTEST'
export const LEAGUE_MY_CONTEST_TEAMS = 'LEAGUE_MY_CONTEST_TEAMS'
export const LEAGUE_SERIES_PLAYER_LIST = 'LEAGUE_SERIES_PLAYER_LIST'
export const LEAGUE_LEADER_BOARD_DATA = 'LEAGUE_LEADER_BOARD_DATA'
export const LEADER_BOARD_SCROLL_DATA = 'LEADER_BOARD_SCROLL_DATA'




// ****************************tabs and other***********************************
export const LEAGUE_MY_CONTEST_TAB = 'LEAGUE_MY_CONTEST_TAB'
export const LEAGUE_DETAILS_TAB = 'LEAGUE_DETAILS_TAB'
export const LEAGUE_CONTEST_DETAILS_TAB = 'LEAGUE_CONTEST_DETAILS_TAB'


// ****************************team details tabs***********************************
export const LEAGUE_TOP_PLAYERS= 'LEAGUE_TOP_PLAYERS'
export const LEAGUE_PLAYERS_POINT_AND_TRANSFER = 'LEAGUE_PLAYERS_POINT_AND_TRANSFER'
export const LEAGUE_LIST_POP_UP = 'LEAGUE_LIST_POP_UP'



// ****************************others***********************************
export const INFO_POP_UP = 'INFO_POP_UP'
export const LEAGUE_TRANSFER_PREVIEW = 'LEAGUE_TRANSFER_PREVIEW'
export const PREVIOUS_APPLIED_BOOSTER = 'PREVIOUS_APPLIED_BOOSTER'
export const LEAGUE_TEAM_PREVIEW = 'LEAGUE_TEAM_PREVIEW'
export const LEAGUE_SELECTED_CONTEST = 'LEAGUE_SELECTED_CONTEST'
export const CHECK_WALLET_BALANCE_LEAGUE = 'CHECK_WALLET_BALANCE_LEAGUE'
export const CONTEST_CONFIRMATION_LEAGUE = 'CONTEST_CONFIRMATION_LEAGUE'
export const SELECTED_TEAM_LEAGUE = 'SELECTED_TEAM_LEAGUE'
export const LOW_BALANCE_LEAGUE = 'LOW_BALANCE_LEAGUE'
export const TEAM_EXITS = 'TEAM_EXITS'
export const EXITS_CREATE_TEAM = 'EXITS_CREATE_TEAM'
export const POINTS_HISTORY_TEAM_SELECT = 'POINTS_HISTORY_TEAM_SELECT'
export const LEAGUE_STATS_TEAM_COUNT = 'POINTS_HISTORY_TEAM_SELECT'
export const PLAYERS_FILTERS_MODEL = 'PLAYERS_FILTERS_MODEL'
export const PLAYERS_FILTERS_SELECT = 'PLAYERS_FILTERS_SELECT'
export const APPLY_PLAYERS_FILTERS = 'APPLY_PLAYERS_FILTERS'
export const SET_PLAYERS_FILTERS_LIST = 'SET_PLAYERS_FILTERS_LIST'
export const PLAYERS_FILTERS_RESET = 'PLAYERS_FILTERS_RESET'




// ***********************************clear constant****************************

export const LEAGUE_MY_CONTEST_TEAMS_CLEAR = 'LEAGUE_MY_CONTEST_TEAMS_CLEAR'
export const CLEAR_LEAGUE_STATES = 'CLEAR_LEAGUE_STATES'
export const CLEAR_LEAGUE_MATCH_DETAILS = 'CLEAR_LEAGUE_MATCH_DETAILS'