import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { BOOSTER_CATEGORY_TABS, BOOSTER_SHOP_TABS, STANDARD_BOOSTERS } from '../../../redux/constants/Shop'
import StandardBoosters from '../standardBoosters/StandardBoosters'
import { useEffect } from 'react'
import { getBoosterListByCategory, getShopCategory } from '../../../redux/actions/ShopActions'
import { useState } from 'react'

const BoostersTab = () => {

    const dispatch = useDispatch()
    // const [boosterCategory, setBoosterCategory] = useState([])

    const { categoryTabs, standardBooster, boosterCategory, boosterCategoryList } = useSelector(state => state.shop)

    const getBoostersByCategory = async (id) => {
        dispatch(getBoosterListByCategory(id))
    }

    const getBoosterCategory = async () => {
        dispatch(getShopCategory())
    }

    const handleBoosterCategoryTab = async (eventKey) => {
        if (categoryTabs !== eventKey) {
            const id = await boosterCategory?.filter(item => item?.name === eventKey)?.map(item => item?.id).toString()
            getBoostersByCategory(id)
            // console.log('category==>>>>>>', id);
        }
        dispatch({ type: BOOSTER_CATEGORY_TABS, payload: eventKey })
    }

    const handleStandardBooster = async (item) => {
        dispatch({ type: STANDARD_BOOSTERS, payload: item })
    }


    useEffect(() => {
        getBoosterCategory()
    }, [])




    useEffect(() => {
        if (boosterCategory?.length > 0) {
            getBoostersByCategory(boosterCategory[0]?.id)
        }
    }, [boosterCategory])


    return (


        standardBooster ? (
            <StandardBoosters />
        ) : (
            <div className="inner_tabs">
                {
                    boosterCategory?.length > 0 &&
                    <Tabs
                        id={`nested-tabs`}
                        className=""
                        activeKey={categoryTabs}
                        onSelect={(eventKey, s) => handleBoosterCategoryTab(eventKey)}
                        defaultActiveKey="Boosters"
                    >

                        {
                            boosterCategory?.map((item, i) => (
                                <Tab eventKey={item?.name} title={item?.name} id={i}>
                                    <div className="booster_list py-4">
                                        <ul>
                                            {
                                                boosterCategoryList && boosterCategoryList?.length > 0 && boosterCategoryList.map((booster, i) => (
                                                    <li onClick={() => handleStandardBooster(booster)} key={i} className='cursor'>
                                                        <figure>
                                                            {/* <img alt="" src="../images/booster.png" /> */}
                                                            <img alt="" src={booster?.image} />
                                                        </figure>
                                                        <figcaption>
                                                            <h4>
                                                                <a href="#">{booster?.name}</a>
                                                            </h4>
                                                            <small>{item?.name}</small>
                                                        </figcaption>
                                                    </li>
                                                ))
                                            }

                                        </ul>
                                    </div>
                                </Tab>
                            ))
                        }
                    </Tabs>
                }

            </div>
        )

    )
}

export default BoostersTab