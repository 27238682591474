import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";


//files import==>>>>>>>>>>>
import RightPanel from "../components/RightPanel";
import LeftPanel from "../components/LeftPanel";
import RugbyHeader from "../pages/rugbyGame/components/rugbyHeader/RubgyHeader";


const RugbyLayout = () => {

    // const dispatch = useDispatch()
    // const navigate = useNavigate()


    const loader = useSelector((state) => state?.loader?.loader);
    const team_loader = useSelector((state) => state?.loader?.team_loader);
    // const { match_details, leagueDetails, league_states } = useSelector(state => state?.league)


    // const getLeagueMatchDetails = async () => {
    //     const payload = { series_id: leagueDetails?.id_api }
    //     dispatch(get_league_states(payload))
    //     dispatch(get_league_match_details(payload))
    // }

    // useEffect(() => {
    //     getLeagueMatchDetails()
    // }, [])

    // useEffect(() => {
    //     if (league_states?.userTeams?.length > 0) {
    //         dispatch({ type: LEAGUE_MY_TEAMS_ACCORDION, payload: league_states?.userTeams[0] })
    //     }
    // }, [league_states])

    return (
        <>

            <div
                className={`main-wrapper-outer 
                 ${loader || team_loader ? "loader" : ""}`}
            >
                {(loader || team_loader) && (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                )}

                <div className="AL_body">
                    <div className="AL_wrap2 h-100">
                        <div className="d-flex h-100">
                            <LeftPanel />

                            <div className="middle_panel">
                                <div className="middlePage_bg h-100">
                                    <RugbyHeader />

                                    <div className="" id="">
                                        <Outlet />
                                    </div>

                             
                                </div>
                            </div>

                            <RightPanel />
                        </div>
                    </div>
                </div>
            </div>

        
        </>
    )
}

export default RugbyLayout



