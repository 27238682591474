import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { get_player_details, get_player_state_for_single_match } from "../redux/actions/FantasyActions";
import MatchTimer from "../utils/MatchTimer";
import { TEAM_PREVIEW } from "../redux/constants/Other";
import _ from "lodash";
import helper from "../utils/helper";

const TeamPreview = (props) => {
  // console.log('props==>>>>>>>>>>>>',props);
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const startTime = parseInt(new Date().getTime() / 1000);

  const { user, profile_image_url, isOpen } = useSelector(state => state?.auth)
  const [state] = useState(props?.state ? props?.state : location?.state)
  const playerTeamList = useSelector(state => state?.match?.playerTeamList?.results)


  //console.log('state>>>>>>>>>>>>>>>>>>>==>>>>>', state)

  const PlayerCard = ({ item, i, type, }) => {
    var name;
    if (item?.player_name) {
      if (item?.player_name.split(" ")?.length === 1) {
        name = item?.player_name
      } else {
        name = `${item?.player_name.split(" ")[0]?.slice(0, 1)}. ${item?.player_name?.split(" ")?.slice(1)?.join(' ')}`
      }
    } else {
      if (item?.name.split(" ")?.length === 1) {
        name = item?.name
      } else {
        name = `${item?.name.split(" ")[0]?.slice(0, 1)}. ${item?.name?.split(" ")?.slice(1)?.join(' ')}`
      }

    }

    const RoleImage = () => {
      const url = type === 'Wicketkeeper' ? "Wicketkeeper"
        : type === 'Batsman' ? "Batsman"
          : type === 'Allrounder' ? "all_rounder"
            : "Bowler"
      return <img className={`${type === 'Wicketkeeper' && 'mb-1'}`} src={`./images/${url}.png`} alt="" />
    }

    // console.log('state?.timestamp_startstate?.timestamp_startstate?.timestamp_startstate?.timestamp_start', state?.timestamp_start, startTime);
    return (
      <div className={`player ${state?.localteam_id === item?.team_id ? 'orange' : 'blue'} text-center cursor`} key={i} onClick={() => handlePlayerDetails(item)}>
        {state?.lineup &&
          <span className={`${item?.is_playing ? "isPlaying-player" : "notPlaying-player"}`}>.</span>
        }
        <div className="for_cc">
          {/* {(item?.isCaptain !== undefined && item?.isViceCaptain !== undefined && (item?.isCaptain || item?.isViceCaptain)) 
          ? <span className="captain_badge position-absolute">{item?.isCaptain ? 'C' : item?.isViceCaptain && 'VC'}</span> :
            (item?.player_id == state?.captain_player_id || item?.player_id == state?.vice_captain_player_id) && item?.isCaptain === undefined &&
            <span className="captain_badge position-absolute">{item?.player_id == state?.captain_player_id ? 'C' : 'VC'}</span>
          } */}

          {(item?.isCaptain !== undefined && item?.isViceCaptain !== undefined && (item?.isCaptain || item?.isViceCaptain))
            ? <span className="captain_badge blue_bage position-absolute" >{item?.isCaptain ? 'C' : item?.isViceCaptain && 'VC'}</span> :
            (item?.player_id == state?.captain_player_id || item?.player_id == state?.vice_captain_player_id) && item?.isCaptain === undefined &&
            <span className="captain_badge blue_bage position-absolute" >{item?.player_id == state?.captain_player_id ? 'C' : 'VC'}</span>
          }

          {/* <div className={`pPic ${state?.localteam_id !== item?.team_id ? 'visitor_team_border' : 'local_team_border'}`} >
            <img src="./images/playerimg.png" alt="" />
          </div> */}

          <div className="">
            <img src="./images/player_image_preview.png" width={'50px'} alt="" />
            {
              item?.team_short_name &&
              <span className="blue_player_label ">{item?.team_short_name}</span>
            }
          </div>

        </div>

        <div className="pName bg-blue cricket_label_with_icon">
          {true && <RoleImage />}
          {helper?.textConverter(name)}
        </div>

        {
          state?.timestamp_start < startTime ? <span className="pt">{item?.points} pts</span> : <span className="pt">{item?.credits ? item?.credits : item?.player_credit} Cr.</span>
        }
      </div>
    )
  }

  const handlePlayerDetails = async (item) => {

    var res
    state['player_id'] = item?.player_id
    if (state?.match_status === 'Completed' || state?.match_status === 'Live' || state?.match_status === 'In Progress') {
      res = await dispatch(get_player_state_for_single_match(state))
      if (res?.results?.length > 0) {
        dispatch({ type: TEAM_PREVIEW, payload: false })
        res = { ...state, ...res }
        res['match_status'] = state?.match_status
        navigate('/player-info', { state: res })
      } else {
        toast.warn(res?.msg)
      }
    } else {
      res = await dispatch(get_player_details(state))
      if (!_.isEmpty(res?.results)) {
        dispatch({ type: TEAM_PREVIEW, payload: false })
        res = { ...state, ...res }
        res['match_status'] = state?.match_status
        navigate('/player-info', { state: res })
      } else {
        toast.warn(res?.msg)
      }
    }

  }

  const handleBack = () => {
    if (props?.state) {
      dispatch({ type: TEAM_PREVIEW, payload: false })
    } else {
      window.history.back()
    }
  }


  useEffect(() => {
    document.getElementById("outlet-container").style.height = "100%";
  }, [])

  const updateTeam = () => {


    dispatch({ type: TEAM_PREVIEW, payload: false })
    if (location?.pathname === '/create-team') {
      dispatch({ type: TEAM_PREVIEW, payload: false })
      return
    }

    if (state?.isEdit || state?.fromSaveTeam) {
      dispatch({ type: TEAM_PREVIEW, payload: false })
      return
    } else {

      var item = {}
      item['localteam_short_name'] = state?.localteam_short_name
      item['visitorteam_short_name'] = state?.visitorteam_short_name
      item['series_id'] = state?.series_id
      item['match_id'] = state?.match_id
      item['team_number'] = state?.team_number
      // item['seriesPlayer'] = playerTeamList && playerTeamList[0]?.seriesPlayer
      item['seriesPlayer'] = state?.seriesPlayer


      if (item?.total_batsman === undefined || item?.total_wicketkeeper === undefined || item?.total_allrounder === undefined || item?.total_bowler === undefined) {
        item['total_batsman'] = state?.total_batsman
        item['total_allrounder'] = state?.total_allrounder
        item['total_bowler'] = state?.total_bowler
        item['total_wicketkeeper'] = state?.total_wicketkeeper
      }
      //console.log('send datat==>>>>>>',item);

      navigate('/update-team', { state: { item: item, mathData: state, playerTeamList } })
    }
  }



  const localTeamCount = state?.seriesPlayer?.filter(player => player?.team_id === state?.localteam_id)?.length;
  const visitorTeamCount = state?.seriesPlayer?.filter(player => player?.team_id === state?.visitorteam_id)?.length;

  return (
    <div className="team-playground-screen" id="team-playground-screen">
      <div className="top_header px-4">
        <div className="d-flex align-items-center">
          <div className="back_arrow">
            <div onClick={() => handleBack()} className="d-flex align-items-center mb-2">
              <img src="./images/top_arrow.png" className="me-3" alt="" />
              {/* {`${user?.username} ${state?.teamName !== undefined ? state?.teamName : 'Team Preview'}`} */}
              {/* {`${user?.username} ${state?.teamName !== undefined ? state?.teamName : 'Team Preview'}`} */}
              {
                state?.username ? helper.textCapitalize(state?.username) + ' ' + state?.teamName : 'Team Preview'
              }

            </div>
            <div className="teamVS">
              <span className="bg-orange">{state?.localteam_short_name} {localTeamCount}</span>
              VS <span className="bg-dark">{state?.visitorteam_short_name} {visitorTeamCount}</span>
            </div>
          </div>

          {/* state?.match_status ?
                <div className="teamPreview-edit-icon d-flex" style={{ color: '#ffff' }}>
                  {state?.match_status !== 'Live' && state?.match_status}&nbsp;
                  
                  {state?.total_point !== undefined &&
                    <h6><span className="d-block">{state?.total_point + 'Pts'} </span></h6>}
                </div>
                : state?.total_point > 0 &&
                <h6><span className="d-block">{state?.total_point} Pts</span></h6> */}

          <div className="right_top_header ms-auto">
            {(state?.timestamp_start > startTime || state?.isEdit || state?.fromSaveTeam) ?
              <div className="teamPreview-edit-icon">
                <MatchTimer item={state} hour={true} class_name={'normal'} hr={'left'} />
                <img src="./images/edit-icon.svg" className="me-3 cursor" alt="" onClick={updateTeam}></img>
              </div>
              :
              state?.match_status === 'Live' || state?.match_status === 'In Progress' ?
                <div className="teamPreview-edit-icon d-flex" style={{ color: '#ffff', display: 'flex', flexDirection: 'column', marginRight: '-30px' }}>
                  {/* <h6><span className="d-block">{state?.seriesPlayer?.reduce((minCount, item) => minCount + item?.points, 0) + 'Pts'} </span></h6> */}
                  <h6><span className="d-block">{state?.teams?.points + 'Pts'} </span></h6>
                  {state?.rank > 0 &&
                    <span className="d-block">#{state?.teams?.rank}</span>}
                </div>
                : state?.match_status === 'Completed' &&
                <h6><span className="d-block">{state?.match_status}</span></h6>
            }
          </div>
        </div>
      </div>
      <div className="cricket_ground">

        <div className="wk playerRow d-flex justify-content-center flex-wrap justify_contest_center" >
          <div className="w-100 text-center">
            <h6 className="mb-2 mx-auto">WICKET-KEEPER</h6>
          </div>
          {state?.seriesPlayer?.length > 0 && state?.seriesPlayer?.map((item, i) => {
            return (
              (item?.role === "Wicketkeeper" || item?.player_role === "Wicketkeeper") &&

              <PlayerCard item={item} i={i} type={item?.role !== undefined ? item?.role : item?.player_role} />
            )
          })}

        </div>

        <div className="Batsman playerRow d-flex justify-content-between flex-wrap justify_contest_center" >
          <div className="w-100 text-center">
            <h6 className="mb-2">Batsman</h6>
          </div>
          {state?.seriesPlayer?.length > 0 && state?.seriesPlayer?.map((item, i) => {
            return (
              (item?.role === "Batsman" || item?.player_role === "Batsman") &&
              <PlayerCard item={item} i={i} type={item?.role !== undefined ? item?.role : item?.player_role} />
            )
          })}

        </div>

        <div className="AllRounder playerRow d-flex justify-content-around flex-wrap justify_contest_center">
          <div className="w-100 text-center">
            <h6 className="mb-2">ALL-ROUNDERS</h6>
          </div>

          {state?.seriesPlayer?.length > 0 && state?.seriesPlayer?.map((item, i) => {
            return (
              (item?.role === "Allrounder" || item?.player_role === "Allrounder") &&
              <PlayerCard item={item} i={i} type={item?.role !== undefined ? item?.role : item?.player_role} />
            )
          })}
        </div>

        <div className="bowler playerRow ">
          {/* <div className="w-100 text-center">
            <h6 className="mb-2">BOWLERS</h6>
          </div>
          <div className="d-flex justify-content-between flex-wrap mb-3 justify_contest_center">
            {state?.seriesPlayer?.length > 0 && state?.seriesPlayer?.map((item, i) => {
              return (
                (item?.role === "Bowler" || item?.player_role === "Bowler") &&
                <PlayerCard item={item} i={i} type={item?.role !== undefined ? item?.role : item?.player_role} />
              )
            })}

          </div> */}

          <div className="bowler playerRow">
            <div className="w-100 text-center">
              <h6 className="mb-2">BOWLERS</h6>
            </div>
            <div className="d-flex justify-content-sm-around flex-wrap mb-3 justify_contest_center">
              {state?.seriesPlayer?.length > 0 && state?.seriesPlayer?.map((item, i) => {
                return (
                  (item?.role === "Bowler" || item?.player_role === "Bowler") &&
                  <PlayerCard item={item} i={i} type={item?.role !== undefined ? item?.role : item?.player_role} />
                )
              })}

            </div>

          </div>


        </div>
      </div>
    </div>
  );
}

export default TeamPreview;
