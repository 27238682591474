export const BOOSTER_CATEGORY_LIST = 'BOOSTER_CATEGORY_LIST'
export const BOOSTER_LIST_BY_CATEGORY = 'BOOSTER_LIST_BY_CATEGORY'


export const BOOSTER_SHOP_TABS = 'BOOSTER_SHOP_TABS'
export const BOOSTER_CATEGORY_TABS = 'BOOSTER_CATEGORY_TABS'
export const STANDARD_BOOSTERS = 'STANDARD_BOOSTERS'


export const BOOSTERS_CATEGORY = 'BOOSTERS_CATEGORY'
export const BOOSTERS_CATEGORY_LIST = 'BOOSTERS_CATEGORY_LIST'
export const SHOP_BOOSTERS_LIST = 'SHOP_BOOSTERS_LIST'
export const SHOP_SUBSCRIPTION_LIST = 'SHOP_SUBSCRIPTION_LIST'
