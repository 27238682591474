import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  Outlet,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Offcanvas,
  Dropdown,
  Nav,
  Button,
  Form,
  FloatingLabel,
  Modal,
  Table,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import {
  CONTEST_CONFIRMATION,
  CONTEST_PDF_MODAL,
  JOIN_CONTSEST,
  LOADER,
} from "../redux/constants/Common";
import { SET_REMINDER } from "../redux/constants/Other";
import LeftPanel from "../components/LeftPanel";
import Balance from "../components/Balance";
import Notification from "../components/Notification";
import RightPanel from "../components/RightPanel";
import BottomMenu from "../components/BottomMenu";
import SideNav from "../components/SideNav";
import { get_profile } from "../redux/actions/AuthActions";
import { OPEN_SIDE_NAV } from "../redux/constants/Auth";
import { INVITE_OPEN } from "../redux/constants/Common";
import { Tooltip as ReactTooltip } from "react-tooltip";
import io from "socket.io-client";
import {
  apply_contest_invite_code,
  contest_team_pdf,
  join_contest,
  user_create_contest,
} from "../redux/actions/FantasyActions";
import { toast } from "react-toastify";
import { Info } from "react-bootstrap-icons";
import CommonHeader from "./CommonHeader";
import CommonBottom from "./CommonBottom";
import USDT from "../components/USDT";
import SoccerDialogs from "../pages/soccerGame/Components/SoccerDialogs";
import Session from "../utils/session";
import { get_match_detail_soccer } from "../redux/actions/SoccerActions";
import helper from "../utils/helper";
import WalletHeader from "../pages/userWallet/WalletHeader";

const AuthLayout = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const loader = useSelector((state) => state?.loader?.loader);
  const team_loader = useSelector((state) => state?.loader?.team_loader);
  const { isInviteOpne, isOpenTeamPDFDownload, contestPayload } = useSelector(
    (state) => state?.loader
  );
  const isJoinContest = useSelector((state) => state?.loader?.isJoinContest);
  const isConfirm = useSelector((state) => state?.loader?.isConfirm);
  const checkWalletBalance = useSelector(
    (state) => state?.match?.checkWalletBalance?.results
  );
  const team_preview = useSelector((state) => state?.static?.teamPreview);

  const { user, profile_image_url, isOpen } = useSelector(
    (state) => state?.auth
  );

  const user_profile = useSelector((state) => state?.auth?.user_profile);

  const [profile, setProfile] = useState(false);
  const [error, setError] = useState(false);

  const [contest, setContest] = useState(false);
  const [balance, setBalance] = useState(null);
  const [inviteCode, setInviteCode] = useState("");
  const [match, setMatch] = useState();
  const [tour, Tour] = useState();

  // const infoClose = () => setProfile(false);
  // const infoShow = () => setProfile(true);

  const infoClose = () => {
    //console.log("close side navvvv");
    dispatch({
      type: OPEN_SIDE_NAV,
      payload: false,
    });
  };

  const infoShow = () => {
    dispatch({
      type: OPEN_SIDE_NAV,
      payload: true,
    });
  };

  const style = {
    corners: 1,
    color: "#06bd53",
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: "50%",
    left: "50%",
    shadow: true,
    hwaccel: true,
    position: "absolute",
  };
  const getProfileData = async () => {
    await dispatch(get_profile());
  };
  useEffect(() => {
    getProfileData();
    setTimeout(() => {
      dispatch({ type: LOADER, payload: false });
    }, 200);
  }, []);

  const GoToKYC = async () => {
    dispatch({ type: OPEN_SIDE_NAV, payload: false });
    navigate("/verifykyc");
  };

  const handleWithdrawal = () => {
    dispatch({ type: OPEN_SIDE_NAV, payload: false });
    navigate("/withdrawamount");
  };

  const handleProfile = () => {
    navigate("/my-profile");
    dispatch({
      type: OPEN_SIDE_NAV,
      payload: false,
    });
  };

  const contestOfcanvas = () => setContest(!contest);

  const handleCloseInvite = () => {
    dispatch({
      type: INVITE_OPEN,
      payload: false,
    });
    setError(false);
  };

  const joinCnvasOpen = () => {
    dispatch({
      type: JOIN_CONTSEST,
      payload: true,
    });
  };
  const handleCloseJoin = () => {
    dispatch({
      type: JOIN_CONTSEST,
      payload: false,
    });
  };

  const handlePDFModal = () => {
    dispatch({
      type: CONTEST_PDF_MODAL,
      payload: { modal: false },
    });
  };

  // console.log("useruseruser", user);

  const handleDownloadPDF = async (type) => {
    var dict = contestPayload;
    if (type === "all") {
      dict["winning_team"] = false;
    }
    const res = await dispatch(contest_team_pdf(dict));
    if (res?.success) {
      fetch(res?.result).then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "My_Team.pdf";
          alink.click();
        });
      });
      handlePDFModal();
    } else {
      toast.error(res?.msg);
    }
  };

  const handleClose = () => {
    dispatch({
      type: CONTEST_CONFIRMATION,
      payload: false,
    });
  };

  const handleJoinContest = async () => {
    let data = {
      contest_size: state?.contest_size,
      match_id: state?.match_id,
      series_id: state?.series_id,
      winning_amount: state?.winning_amount,
      entry_fee: state?.entryFee,
      team_id: state?.team_id,
      winners_count: state?.winners_count,
      contest_name: state?.contest_name,
      join_multiple: state?.isMultiple ? "yes" : "no",
    };

    if (state?.isInvitation !== undefined && state?.isInvitation) {
      let dict = {
        contest_id: state?.contest_id,
        team_id: state?.team_id,
        series_id: state?.series_id,
        match_id: state?.match_id,
      };

      const res = await dispatch(join_contest(dict));

      if (res?.success) {
        handleClose();
        dispatch(get_profile());
        navigate("/AllContest");
        toast.success(res?.msg);
      } else {
        toast.error(res?.msg);
        handleClose();
      }
    } else {
      const res = await dispatch(user_create_contest(data));

      if (res?.success) {
        handleClose();
        navigate("/share-contest");
      } else if (
        res?.success === false &&
        res?.msg === "You do not have sufficient balance to join this contest."
      ) {
        toast?.error(res?.msg);
      }
    }
  };
  const handleContestInvite = async () => {
    if (inviteCode !== "") {
      const res = await dispatch(apply_contest_invite_code(inviteCode));

      if (res?.match_type === "soccer") {
        await Session.setSession("homeActiveKey", "soccer");
        await dispatch(get_match_detail_soccer(res?.results));
        dispatch({ type: INVITE_OPEN, payload: false });
        navigate("/soccer-contest-details", {
          state: { _id: res?.results?.contest_id },
        });
        return;
      } else {
        Session.setSession("homeActiveKey", "cricket");
      }

      const timestamp = parseInt(
        new Date(res?.results?.match_date).getTime() / 1000
      );
      res.results["isInvitation"] = true;
      res.results["timestamp_start"] = timestamp;
      if (res?.success) {
        dispatch({
          type: INVITE_OPEN,
          payload: false,
        });
        navigate("/contest-details", { state: res?.results });
      } else if (res?.success === false) {
        toast.error(res?.msg);
      }
    } else {
      setError(true);
    }
  };

  const closeSidenav = () => {
    // navigate("/addcash");
    dispatch({
      type: OPEN_SIDE_NAV,
      payload: false,
    });
  };

  const handleInviteCode = (e) => {
    setInviteCode(e.target.value);
    if (inviteCode?.length > 0) {
      setError(false);
    } else {
      setError(true);
    }
  };
  // console.log('auth layout calledddddd')

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);

    socket
      .off(`updateBalance_${user?._id}`)
      .on(`updateBalance_${user?._id}`, (data) => {
        setBalance(data.balance);
        console.log("event name==>>>>>>", `updateBalance_${user?._id}`, data);
      });
  }, []);

  const walletPage = () => {
    navigate('/addcash')
    dispatch({ type: OPEN_SIDE_NAV, payload: false })

  }

  return (
    <>
      {/* <ThreeCircles
        wrapperStyle={style}
        ariaLabel="loading-indicator"
        height={100}
        width={100}
        strokeWidth={5}
        strokeWidthSecondary={2000}
        color="green"
        secondaryColor="red"
        visible={loader || team_loader}
      /> */}

      {(loader || team_loader) && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      <div
        className={`main-wrapper-outer   ${loader || team_loader ? "loader" : ""
          }`}
      >
        {/* {
          paymentObj?.payment_link &&
          <iframe src={paymentObj?.payment_link} style={{ height: '100vh', width: '100%' }} />
        } */}

        <div className="AL_body">
          <div className="AL_wrap2 h-100">
            <div className="d-flex h-100">
              <LeftPanel />

              <div className="middle_panel">
                <div className="middlePage_bg h-100">
                  {!["/team-preview", "/soccer-team-preview"].includes(
                    location?.pathname
                  ) &&
                    !team_preview && (
                      <div className="top_header px-4">
                        <div className="d-flex align-items-center">
                          <CommonHeader />
                          {!["/profile",].includes(location?.pathname) && (
                            <div className="right_top_header ms-auto">
                              {/* here static balance was show in case of nishant sir id for testing purpose */}
                              {!["/transaction"].includes(
                                location?.pathname
                              ) && (
                                  <Link
                                    className="remain_money cursor"
                                    // style={{ pointerEvents: "none" }}
                                    to="/addcash"
                                  >

                                    {balance
                                      ? helper.localizeNumber("coin", balance)
                                      : helper.localizeNumber("coin", user?.total_balance)}{" "}
                                    <img
                                      className="ms-1"
                                      src="./images/plus.png"
                                      alt=""
                                    />
                                  </Link>
                                )}
                              <Notification />
                            </div>
                          )}
                        </div>

                        <WalletHeader />


                      </div>
                    )}

                  <div className="full_page_scroll" id="outlet-container">
                    <Outlet />
                  </div>
                  {/* outlet */}

                  <CommonBottom />
                </div>
              </div>

              <RightPanel />
            </div>
          </div>
        </div>
      </div>

      <Offcanvas show={isOpen} onHide={infoClose} className="profile_offcanvas">
        <Offcanvas.Body className="p-0">
          <div className="canvas_header d-flex align-items-baseline ps-4 cursor">
            <a onClick={() => handleProfile()}>
              <div className="profile_edit position-relative pt-5 mt-3">
                <img
                  src={user_profile.image}
                  className="me-3 profile_img"
                  alt=""
                />
                <div className="upload_pic">
                  {/* <input type="file" className="edit_icon position-absolute" /> */}
                  <img src="./images/edit_icon.svg" className="me-3 " alt="" />
                </div>
              </div>
            </a>
            <div>
              <a onClick={() => handleProfile()}>
                <h5 className="text-white ">{user?.full_name}</h5>
              </a>
              {user_profile.emailverified &&
                user_profile.identity_verified == 3 ? (
                <div onClick={GoToKYC}>
                  <div className="verified cursor">
                    <img
                      src="./images/verify_icon.svg"
                      className="me-1"
                      alt=""
                    />
                    KYC Verified
                  </div>
                </div>
              ) : (
                <Button onClick={GoToKYC} type="button" className="verify_kyc">
                  Verify KYC
                  <img
                    src="./images/orange_angle.svg"
                    className="ms-2"
                    alt=""
                  />
                </Button>
              )}
            </div>
          </div>

          <div className="avail_balance d-flex align-items-center justify-content-between">
            <h5 className="mb-0">Available Balance</h5>
            {/* <Balance user={user}/> */}
            <div onClick={closeSidenav} className="remain_money cursor" onClick={walletPage}>
              {balance
                ? helper.localizeNumber("coin", balance)
                : helper.localizeNumber("coin", user?.total_balance)}{" "}
              <img className="ms-3" src="./images/plus.png" alt="" />
            </div>
          </div>
          <div className="sidebar p-3">
            <SideNav />
            <div className="app_version d-flex px-3">
              <h5>
                App version 1.2 <span className="d-block">App up to date </span>
              </h5>
              <Link className="ms-auto" to="">
                UPDATE
              </Link>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* contest-offcanvas */}
      <Offcanvas
        show={isInviteOpne}
        onHide={handleCloseInvite}
        placement={"bottom"}
        className="offcanvas-common contest-canvas"
      >
        <Offcanvas.Header closeButton className="">
          <div className="d-flex justify-content-between align-items-center">
            <Offcanvas.Title className="text-dark">Contest</Offcanvas.Title>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <div className="kyc_card contest-card mt-4 px-sm-3 px-0">
              <Form.Label className="mb-5">
                If you have a invite code, enter it and Join Contest.
              </Form.Label>
              <FloatingLabel
                controlId="floatingPassword"
                label="Enter Invite Code"
                className="mb-4 fw-bold"
                style={{ color: error ? "red" : "" }}
              >
                <Form.Control
                  style={{ borderColor: error ? "red" : "" }}
                  type="text"
                  placeholder="Ashutosh Sharma"
                  value={inviteCode}
                  onChange={(e) => handleInviteCode(e)}
                />
              </FloatingLabel>

              <Button
                className="btn-green w-100"
                onClick={() => handleContestInvite()}
              >
                Join This Contest
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {/* contest-offcanvas */}

      {/* download contest Team */}
      <Offcanvas
        show={isOpenTeamPDFDownload}
        onHide={() => handlePDFModal()}
        placement={"bottom"}
        className="offcanvas-common contest-canvas"
      >
        <Offcanvas.Header closeButton className="">
          <div className="d-flex justify-content-between align-items-center filter-heading">
            <Offcanvas.Title>Download Teams</Offcanvas.Title>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <div className="create-float-right download">
              <div
                onClick={() => handleDownloadPDF("team")}
                style={{ cursor: "pointer" }}
              >
                <img
                  src="./images/downloadgray.svg"
                  alt=""
                  className="me-2"
                  style={{ float: "right" }}
                />
                { }
                Winning Teams
              </div>
            </div>
            <br />

            <div className="create-float-contest download">
              <div
                onClick={() => handleDownloadPDF("all")}
                style={{ cursor: "pointer" }}
              >
                <img
                  src="./images/downloadgray.svg"
                  alt=""
                  className="me-2"
                  style={{ float: "right" }}
                />
                { }
                All Teams
              </div>
            </div>
            <br />
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {/* download contest team */}

      {/* Contest join confirmation */}
      <Modal
        show={isConfirm}
        onHide={handleClose}
        className="contest_confirmation dark_theme_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>CONFIRMATION </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <span style={{ fontSize: "12px" }}>
            {/* Remaining Balance (excluding Bonus) = <USDT /> */}
            Unutilized Balance + Winnings = <USDT />{" "}
            {Number(
              checkWalletBalance?.cash_balance +
              checkWalletBalance?.winning_balance
            )?.toLocaleString()}
          </span>
          <Table className="contest_confirmation_table mt-2">
            <tbody>
              <tr>
                <td>Entry</td>
                <td>
                  <USDT /> {checkWalletBalance?.entry_fee}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "700" }}>
                  Usable Cash Bonus{" "}
                  <img
                    src="./images/info.png"
                    alt=""
                    id="bonus-tooltip"
                    style={{ maxWidth: "17px", maxHeight: "17px" }}
                  />
                </td>
                <td
                  className={
                    checkWalletBalance?.usable_bonus == 0 ? "" : "text-danger"
                  }
                >
                  <USDT />{" "}
                  {checkWalletBalance?.usable_bonus == 0
                    ? Number(checkWalletBalance?.usable_bonus).toFixed(1)
                    : -Number(checkWalletBalance?.usable_bonus).toFixed(1)}
                </td>
              </tr>

              <tr className="total_value">
                <td>
                  <b>To Pay</b>
                </td>
                <td>
                  <b>
                    <USDT />{" "}
                    {Number(
                      checkWalletBalance?.entry_fee -
                      checkWalletBalance?.usable_bonus
                    ).toFixed(2)}
                  </b>
                </td>
              </tr>
            </tbody>
            <ReactTooltip
              anchorId={`bonus-tooltip`}
              place="bottom"
              variant="info"
              style={{ fontSize: "8px" }}
              content={() => (
                <>
                  Max 10% of total entry fee* per match *valid for selected
                  contests <br /> only *not valid for private contests
                </>
              )}
            />
          </Table>
          {/* <p>
            By Joining this contest you accept T&C and confirm, that you are not
            a resident of Assam, Odisha, Telangana or Sikkim{" "}
          </p> */}
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <Button
              variant="primary"
              onClick={() => handleJoinContest()}
              className="border-0 w-100"
            >
              Join Contest
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Contest join confirmation */}

      <SoccerDialogs />
    </>
  );
};

export default AuthLayout;
