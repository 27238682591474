import React from "react";
import { Link, useNavigate, useLocation, } from "react-router-dom";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

//files==>>>> import

import MatchHeader from "./MatchHeader";
import helper from "../../../../utils/helper";
import Notification from "../../../../components/Notification";

const RugbyHeader = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { myContestTab, match_details, leagueDetails } = useSelector(state => state?.league)
  const { user } = useSelector((state) => state?.auth);

  // console.log('user', user);

  const handelBack = () => {
    const pathname = location?.pathname;

    // if (pathname === '/league-create-team') {
    //   // dispatch({ type: EXITS_CREATE_TEAM, payload: true })
    //   return
    // }


    // if (pathname === '/league-home') {
    //   navigate('/league-fantasy', { state: '', replace: true })
    //   return
    // }

    navigate(-1);
    window.history.pushState(null, '', '/');
  }

  const createTeam = async () => {
    // const res = await dispatch(checkUnJoinedTeams())
    // if (res?.success) {
    //   var data = { "series_id": match_details?.series_id }
    //   Session?.clearSession('reload')
    //   const { results } = await dispatch(get_all_player_list_league(data, 'Create Team'))
    //   if (!isEmpty(results)) {
    //     var leagueData = { isDirectJoin: false, type: 'create_team', team_id: '' }
    //     dispatch({ type: LEAGUE_STATE_DATA, payload: leagueData })
    //     navigate('/league-create-team')
    //   } else {
    //     // toast.warn('Record Not Found!')
    //     navigate('/league-create-team', { state: { isRecord: true } })
    //   }
    // } else {
    //   // toast.warn(res?.msg)
    //   dispatch({ type: TEAM_EXITS, payload: res?.msg })
    // }

  }

  const pathname = location?.pathname

  const pageNames = {
    "/all-contest-rugby": "My Contests"
  }


  const pageName = pageNames[pathname]



  const sethomePage = () => {
    navigate('/Fantasy')
  }


  return (
    <>


      <div className={`top_header px-4 `} >
        <div className="back_arrow cursor">
          <div className="d-flex align-items-center">
            <div>
              <img src="./images/top_arrow.png" className="me-3" alt="" onClick={handelBack} />
              <span className="fw_500">{helper?.textConverter(pageName, 12)}</span>
            </div>


            <div>
              <img className='ms-4' src='./images/home_menu.png' alt='' height={'29px'} width={'28px'} onClick={sethomePage} />
            </div>

            <div className="right_top_header ms-auto">

              <Link className="remain_money_rugby " style={{ pointerEvents: "none" }} >
                {user?.total_balance && helper.localizeNumber("$", user?.total_balance)}{" "}
                <img className="ms-1" src="./images/plus.png" alt="" />
              </Link>


              <Notification />
            </div>


          </div>

          <MatchHeader />

        </div>



      </div>

    </>
  );
};

export default RugbyHeader;
