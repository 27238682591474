import { BOOSTERS_CATEGORY, BOOSTERS_CATEGORY_LIST, BOOSTER_CATEGORY_TABS, BOOSTER_SHOP_TABS, SHOP_BOOSTERS_LIST, SHOP_SUBSCRIPTION_LIST, STANDARD_BOOSTERS } from "../constants/Shop";




let initialState = {
    shopTabs: 'boosters',
    categoryTabs: 'Boosters',
    standardBooster: false,
    boosterCategory: [],
    boosterCategoryList: [],
    shopBoosterList: [],
    subscriptionList: [],
};



const ShopReducer = function (state = initialState, action) {
    try {
        switch (action.type) {
            case BOOSTER_SHOP_TABS:

                if (action?.payload === 'boosters') {
                    state.standardBooster = false
                }
                return {
                    ...state,
                    shopTabs: action.payload
                };

            case BOOSTER_CATEGORY_TABS:
                return {
                    ...state,
                    categoryTabs: action.payload
                };
            case STANDARD_BOOSTERS:
                return {
                    ...state,
                    standardBooster: action.payload
                };
            case BOOSTERS_CATEGORY:
                return {
                    ...state,
                    boosterCategory: action.payload
                };
            case BOOSTERS_CATEGORY_LIST:
                return {
                    ...state,
                    boosterCategoryList: action.payload
                };
            case SHOP_BOOSTERS_LIST:
                return {
                    ...state,
                    shopBoosterList: action.payload
                };
            case SHOP_SUBSCRIPTION_LIST:
                return {
                    ...state,
                    subscriptionList: action.payload
                };

            default:
                return state;
        }
    } catch (error) {
        console.log('err in shopReducer==>>>>', error)
    }
};

export default ShopReducer;



