import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Offcanvas } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getSubscriptionList, purchaseSubscription } from '../../../redux/actions/ShopActions'
import { toast } from 'react-toastify'

const SubscriptionsTab = () => {
  const dispatch = useDispatch()

  const { subscriptionList } = useSelector(state => state.shop)


  const [show, setShow] = useState(false)
  const [quantity, setQuantity] = useState(1)

  const handleClose = () => {
    setShow(false)
  }
  const handleOpen = (item) => {
    setShow(item)
  }

  const getSubscriptionsList = () => {
    dispatch(getSubscriptionList())
  }

  useEffect(() => {
    getSubscriptionsList()
  }, [])


  const handleInc = () => {
    setQuantity(prev => prev + 1)
  }
  const handleDec = () => {
    setQuantity(prev => prev >= 2 ? prev - 1 : 1)
  }


  const purchase = async () => {

    const payload = {
      "subscriptionId": show?.id,
      "validityInMonth": quantity
    }
    const res = await dispatch(purchaseSubscription(payload))
    if (res?.success) {
      toast.success(res?.msg)
    } else {
      toast.error(res?.msg)
    }

    setQuantity(1)
    setShow(false)
  }

  return (
    <>
      <div className="py-3">

        {
          subscriptionList && subscriptionList?.length > 0 && subscriptionList.map((item, i) => (
            <div className="subscription_list ">
              {/* <img src="images/subscription.jpg" /> */}
              <img src={item?.image} alt='' width={'380px'} height={'241px'} />
              <div className="subscription_caption">
                <h2>
                  <span className="text-green">{item?.title}</span>
                  {/* <br /> Subscription */}
                </h2>
                <p>{item?.description}</p>
                <Button
                  className="booster_theme_btn w-100 mt-3"
                  onClick={() => handleOpen(item)}
                >
                  Buy
                </Button>
                <div className="standard_value subscription_page">
                  <span>{item?.price}</span>
                  <img src="../images/standard_logo.png" alt="" />
                </div>
              </div>
            </div>
          ))
        }


      </div>




      {/* offcanvas */}

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        className="theme_offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='text-dark fw_700'>Purchase confirmation</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="pffcanvas_data">
            <div className="banner_wrap">
              {/* <img src="../images/offcanvas_banner.png" /> */}
              <img src={show?.image} alt='' width={'380px'} height={'241px'} />
              <div className="ofcanvas_play_count">
                <span className='text-dark fw_700'>{show?.title}</span>
                <div className="playing_count">
                  <img src="../images/standard_logo.png" />
                  <span>{show?.price}</span>
                </div>
              </div>
            </div>

            <div className="fix_article">
              <p>{show?.description}</p>
            </div>

            <div className="package_timing">

              <span className={` cursor ${quantity === 1 ? "bg_blue_light" : "bg_yellowfix"}`} onClick={() => setQuantity(1)}>1 month</span>
              <span className={`cursor ${quantity === 2 ? "bg_blue_light" : "bg_yellowfix"}`} onClick={() => setQuantity(2)}>2 month</span>
              <span className={`cursor ${quantity === 3 ? "bg_blue_light" : "bg_yellowfix"}`} onClick={() => setQuantity(3)}>3 month</span>

            </div>

            <div className="or_divider">
              <span>or</span>
            </div>

            <div className="quantity_main">
              <strong className='text-dark fw_700'>Number of months</strong>

              <div className="quantity">
                <span onClick={handleDec}>
                  <img src="../images/minus.svg" />
                </span>
                {/* <input type="number" /> */}
                <strong className='ms-4 me-4 fw_700 text-dark'>{quantity}</strong>
                <span onClick={handleInc} >
                  <img src="../images/plush.svg" />
                </span>
              </div>
            </div>



            <div className="subtotal">
              <strong className='text-dark fw_700'>Subtotal</strong>

              <div className="playing_count">
                <img src="../images/standard_logo.png" />
                <span className='text-dark fw_700'>{(show?.price * quantity)}</span>
              </div>
            </div>

            <Button className="booster_theme_btn my-3" onClick={purchase}>Confirm</Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>


    </>
  )
}

export default SubscriptionsTab